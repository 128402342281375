@import "./../../colorvariable.scss";
@import "./../../mixin.scss";

.main-page-component,
.main-page-right-side,
.main-page-left-side {
  width: 100vw;
  height: 100vh;
  width: 100dvw;
  height: 100dvh;

  @include flex-all-center;
}

.main-page-right-side,
.main-page-left-side {
  flex-direction: column;
  overflow: auto;
}

.main-page-left-side {
  color: white;
  background: #5a3594;
  background: linear-gradient(45deg, #5a3594 0%, #3b4bb9 35%, #2029a0 60%, #151a95 80%, #2f4992 100%);
  background-size: 120%;
  background-position: top right;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  .admission-icon {
    width: auto;
    max-height: 50dvh;
  }

  .admission-title,
  .admission-subtitle {
    padding: 0 1rem;
    text-align: center;
    font-weight: 600;
  }

  .admission-title {
    font-size: 2rem;
  }

  .admission-subtitle {
    font-size: 1.231rem;
  }
}

.main-page-right-side {
  gap: 2rem;
  padding: 2rem;
  justify-content: flex-start;

  .school-logo {
    height: 7.154rem;
    width: auto;
    margin-bottom: 0.615rem;
  }

  .school-name {
    font-size: 1.077rem;
    font-weight: 600;
    color: #272A84;
  }

  .bottom-subtitle {
    color: $label-color-dark-gray;
    font-weight: 600;
    font-style: italic;
    font-size: 1.231rem;
  }

  .main-page-ps-logo {
    height: 3rem;
  }

  .formContainer {
    background: #fcfdfe;
    box-shadow: inset 0px 3px 52px #DCEAFC;
    border: 1px solid $border-color;
    width: 32rem;
    min-width: 300px;
    border-radius: 1em;
    padding: 2.769rem;

    &.formFlex {
      @include flex-all-center;
      flex-direction: column;
      gap: 1.923rem;
    }
  
    & select {
      border: 1px solid #dae7fd;
      height: 3.2em;
      line-height: auto !important;
      background: white !important;
      box-shadow: -0.2em 0px 0.6em #dae7fd;
    }
  
    & input.form-control {
      font-weight: 500;
      height: 3.923rem;
      padding: 1.385rem;
      box-shadow: 0 0.231rem 0.462rem #dae7fd;
      transition: none;
      border-radius: 5px;
  
      &[type="password"] {
        font-size: 2rem;
        transition: none;
      }
    }
  
    & .btn {
      background: var(--blue-primary);
      padding: 1rem 0.5rem;
      border-radius: 0.5rem;
      width: 100%;
      color: white;
      font-weight: 600;
      margin-top: 0.5rem;
    }
  
    & .btn-primary-login {
      background: linear-gradient(90deg,
          var(--label-color-blue) 0%,
          #195eba 100%);
      font-size: 1.308rem;
      padding: 1.1rem 0.5rem;
  
      &:hover:not(:disabled) {
        background: linear-gradient(90deg,
            var(--blue-primary) 0%,
            var(--blue-primary) 100%);
        border: 1px solid var(--blue-primary);
        color: white;
      }
    }
  
    & .form-group,
    & .form-label-group {
      margin-bottom: 3.077rem;
  
      &.smallMargin {
        margin-bottom: 2.7rem;
      }
    }
  
    .form-label-group.in-border label:after {
      top: 0;
      height: 80%;
      border-radius: 0.3rem;
    }
  
    .pwd-eye {
      position: absolute;
      padding: 1rem;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.769rem;
      color: $label-color-gray;
  
      &.active {
        color: var(--label-color-blue);
      }
    }
  
    .label-message {
      font-size: 1.077rem;
    }
  
    .form-label-group.in-border input:focus,
    .form-label-group.in-border textarea:focus,
    .form-label-group.in-border select:focus {
      box-shadow: 0 0.231rem 0.462rem #dae7fd;
    }
  
    .form-label-group.in-border input:focus~label,
    .form-label-group.in-border textarea:focus~label {
      color: var(--label-color-blue);
    }
  
    .form-label-group.in-border input:placeholder-shown:not(:focus)~label,
    .form-label-group.in-border textarea:placeholder-shown:not(:focus)~label {
      color: $label-color-gray;
    }
  
    .form-label-group.in-border input:placeholder-shown:not(:focus),
    .form-label-group.in-border textarea:placeholder-shown:not(:focus) {
      border: none;
    }
  
    .form-label-group.in-border input.border-danger:not(:focus),
    .form-label-group.in-border textarea.border-danger:not(:focus) {
      border-color: $label-color-red;
    }
  
    .form-label-group.in-border input.border-danger~label,
    .form-label-group.in-border textarea.border-danger~label {
      color: $label-color-red;
    }

    .form-label-group.in-border input.border-green:not(:focus),
    .form-label-group.in-border textarea.border-green:not(:focus) {
      border-color: $label-color-green;
    }
  
    .form-label-group.in-border input.border-green~label,
    .form-label-group.in-border textarea.border-green~label {
      color: $label-color-green;
    }
  
    .heading {
      font-weight: 800;
      color: $label-color-dark-gray;
      font-size: 2.5rem;
    }
  
    .text-notice {
      font-size: 0.846rem;
      font-weight: 500;
      font-style: italic;
      margin-top: 0.769rem;
      color: $label-color-dark-gray;
  
      &.red {
        color: red;
      }
  
      &.green {
        color: $label-color-green;
      }
    }

    .uname-avail {
      position: absolute;
      padding: 1rem;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.769rem;
      color: $label-color-gray;
      @include flex-all-center;

      &-img {
        width: 1.923rem;
        height: 1.923rem;
        object-fit: cover;
      }
    }
  }
}

.errorMsgBox {
  background: $label-color-red;
  min-width: 25%;
  max-width: 50vh;
  max-width: 50dvh;
  width: max-content;

  display: flex;
  align-items: center;
  position: fixed;
  bottom: -10rem;
  opacity: 0;
  color: white;
  font-weight: 500;
  z-index: 2000;
  border-radius: 0.385rem;
  padding: 1.154rem;

  transition: all 0.2s linear;

  &.show {
    bottom: 1rem;
    opacity: 1;
  }

  .close-btn {
    position: absolute;
    right: 0;
    padding: 1.154rem;
  }

  &.successBox {
    background: $label-color-green;
  }
}
