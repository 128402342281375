@import "./../../colorvariable.scss";

.no-content-component {
	width: 100%;
	height: max-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 2rem 0;
	color: $label-color-dark-gray;

	&__title,
	&__description {
		width: 100%;
		text-align: center;
	}

	&__icon {
		width: 20rem;
		height: auto;
		margin-bottom: 1.5rem;
	}

	&__onModal {
		width: 9.3rem;
		height: auto;
		margin-bottom: 0.5em;
		margin-top: -3em;
	}

	&__title {
		font-size: 1.7rem;
		font-weight: 600;
	}
}
