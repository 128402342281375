@import "./../../colorvariable.scss";
$headerHeight: calc(100vh - 10px);

// Variables for sidebar
$sidebarWidth1: 11.1em; // Roughly 144px
$sidebarWidth4: 7em; // 91px
$sidebarIconSize: 1.8em; // Roughly 23px
$sidebarRoundedBorderSize: 0.8em; // Roughly 10px
$sidebarDivSize: 2.308em; // Roughly 30px

.main_content_container {
	padding-top: 0;
	padding-bottom: 0;
	margin-left: 2px;
}

.mainContainerDiv {
	width: 100vw;
	height: 100vh;
	overflow: hidden !important;
}

.mainContainerDiv::-webkit-scrollbar {
	width: 0px !important;
	height: 0px !important;
}

@media only screen and (max-width: 768px) {
	#root {
		overflow: hidden;
	}
}
.info-name {
	text-transform: capitalize;
}
.block-menu li {
	cursor: pointer;
}

.circleImageBorder {
	border: 2px solid white;
	box-shadow: $Colorshadow;
	border-radius: 100%;
	width: 2.154rem;
	height: 2.154rem;
	object-fit: cover;
	text-align: center;
	margin: 0 auto;
	transition: 0.2s;
	margin-bottom: 0.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-weight: 600;
	padding: 1rem;
}

.borderbtm {
	border-bottom: 1.5px solid $line-header;
	margin-left: 0px;
	margin-right: 0px;
}

.dropdown-menu-right {
	padding-left: 0;
}

.dropdown-years {
	width: 60%;
	text-align: center;
	background: $hover-color;
	border-color: $border-color;
	padding-bottom: 0;
	margin-top: -26px;
	margin-left: -18px;
	& a {
		border-bottom: 1px solid $border-color;
	}
}

.workbook-label {
	color: var(--blue-primary);
}

.year-label {
	font-weight: 600;
}

.header {
	position: relative;
	height: auto;
	z-index: 1;
	border-top-left-radius: 100px;
	& a {
		font-size: 20px;
	}
	& .pr0 {
		padding-right: 0;
	}
	& .pl0 {
		padding-left: 0;
	}
	& .col-1,
	.col-8,
	.col-3 {
		height: 46px;
	}
	& .logo {
		width: 100%;
		cursor: pointer;
		& img {
			// max-width: 15%;
			max-height: 40px;
		}
	}
	& .navigation {
		display: flex;
		align-items: center;
		& .icon-btn {
			background: #a6b5b8;
			width: 35px;
			height: 35px;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 30px;
			margin-right: 8px;
			text-decoration: none;
			&.float-right {
				margin-left: auto;
			}
		}
	}
	& .messaageBox {
		float: left;
		margin-left: 60px;
		width: max-content;
		margin-top: 0.6em;
		position: relative;
		& span {
			position: absolute;
			right: -0.5em;
			top: -0.4em;
			background: $notificationColor;
			color: white;
			line-height: 1;
			min-width: 1.2em;
			height: 1.2em;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			font-size: 0.85em;
			box-shadow: $Colorshadow;
		}
	}
	& .setting {
		display: flex;
		align-items: center;
		padding: 0px 20px 0px 0px;
		margin-right: 0;
		float: right;
		background: #fff;
		border-radius: 100px;
		margin: 5px 35px 5px 5px;
		& .picture {
			& .circleImageBorder {
				border: 2px solid #fff;
				box-shadow: $Colorshadow;
				border-radius: 100%;
				height: 100%;
			}
		}
		& .info {
			text-transform: capitalize;
			flex: 1;
			font-size: 13px;
			line-height: 1.3;
			color: #707070;
			& span {
				display: block;
				&.name-info {
					font-weight: bold;
					color: var(--blue-primary);
					width: 110px;
				}
			}
		}
		& .menu {
			// width: 30px;
			& a {
				width: 30px;
				background: #ebeff2;
				height: 20px;
				border-radius: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #939d9e;
				text-decoration: none;
				overflow: hidden;
				box-shadow: 2px 2px 3px #00000033;
			}
			&.dropdown .dropdown-menu {
				position: absolute;
				background: #fff;
				border: none;
				width: 180px;
				padding: 0;
				border-radius: 4px;
				top: 3.5em;
				right: 0;
				transform: none;
				will-change: unset;
				box-shadow: $Colorshadow;
				overflow: hidden;
				& a {
					color: #4c4c4c;
					width: 100%;
					display: block;
					height: auto;
					padding: 5px 10px;
					font-size: 13px;
					border-radius: 0;
					background: transparent;
					box-shadow: none;
					cursor: pointer;
					&:hover {
						background: $fadedThemeColor;
						color: white;
						font-weight: 600;
					}
				}
			}
		}
	}
	& .userDetailsHeader {
		float: left;
		& .info {
			float: left;
			text-align: left;
			height: 51px;
			padding-top: 0.54em;
			& .name-info {
				width: 100%;
			}
			& .class-info {
				width: 100%;
			}
		}
		& .picture {
			float: left;
			border-radius: 100%;
			overflow: hidden;
			width: 50px;
			height: 50px;
			// border: 2px solid #fff;
			// box-shadow: 0 3px 6px rgba(0,0,0, 0.25);
			margin-left: 0.2em;
			& img {
				max-width: 100%;
				height: auto;
				border-radius: 100%;
			}
		}
	}
}
.info {
	.name-info,
	.class-info,
	.school-info {
		font-size: 1em;
		padding-left: 10px;
		margin-top: 2px;
	}
}

.class-info {
	color: #525151;
	font-weight: 600;
}

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

.positionRelative {
	position: relative;
}
.sidebarContainer {
	float: right;
	height: 100vh;
	overflow: hidden;
}
.contentContainer {
	float: left;
	background: $white-color;
	background-color: $white-color;
}
.sidebarWidth1 {
	width: $sidebarWidth1;
	transition: 0.5s;
}
.contentWidth1 {
	width: calc(100% - #{$sidebarWidth1});
	transition: 0.5s;
	background: var(--blue-primary);
}

.contentWidth2 {
	width: calc(100% - #{$sidebarWidth4});
	transition: 0.5s;
	background: var(--blue-primary);
}

.sidebarWidth1 .avatar-left {
	height: 49px;
}

// menu colapse

.sidebarWidth1 {
	position: relative;
	width: 4.1em;
	float: right;
	background: #fdfeff;
	z-index: 1030;
	box-shadow: -2px 0 1rem rgb(194, 194, 194);
	& .block-menu {
		list-style: none;
		width: 100%;
		padding: 0 0 60px 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		& li {
			border-radius: $sidebarRoundedBorderSize;
			height: $sidebarDivSize;
			width: $sidebarDivSize;
			margin-top: 0.4em;
			margin-bottom: 2em;
			transition: all 0.5s;
			padding: 0.8em 0.4em;
			position: relative;
			cursor: pointer;
			& div {
				opacity: 0;
				display: flex;
				width: $sidebarDivSize;
				height: $sidebarDivSize;
				overflow: hidden;
				text-align: left !important;
				white-space: nowrap;
				font-weight: 600;
				left: 0.6em;
				justify-content: center;
				align-items: center;
				position: fixed;
				padding-top: 0.8em;
				padding-bottom: 0.8em;
				z-index: 99;
				background: linear-gradient(
					to right,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 0.856),
					rgb(255, 255, 255)
				);
				border-radius: $sidebarRoundedBorderSize;
				padding: 0.5em;
				margin-top: -0.8em;
				color: var(--blue-primary);
				box-shadow: -5px 3px 19px var(--blue-primary),
					6px 2px 9px 0px var(--blue-primary);
			}
			:hover {
				opacity: 1;
				width: auto;
				padding-left: 4.6em;
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
			&.active {
				background-color: #fff;
			}
			&.avatar-left {
				padding: 0 0.4em;
			}
			&.icon-menu {
				background: no-repeat url(../../assets/note-icon/arrow-back.svg);
				background-size: 100%;
				background-position: center;
				background-color: #f2f2f2;
				color: $bg-icon;
				border-radius: 50%;
				height: 42px;
				width: 42px;
				position: fixed;
				bottom: 0;
				right: -21px;
				box-shadow: 0 0.5rem 1rem #6362626c;
				z-index: auto;
				transition: all 0.1s ease-out;
				&.active {
					background: var(--blue-primary);
					background-position: center;
					color: $hover-color;
					background-color: $hover-color;
				}
			}
			&.icon-cancel {
				background: no-repeat url(../../assets/note-icon/arrow-show.svg);
				background-size: 100%;
				background-position: center;
				color: $bg-icon;
				border-radius: 50%;
				height: 40px;
				width: 42px;
				position: fixed;
				bottom: 0;
				z-index: auto;
				transition: all 0.1s ease-in;
				&.active {
					background: var(--blue-primary);
					background-position: center;
					color: $hover-color;
					background-color: $hover-color;
				}
			}
		}
	}
}

.sidebarWidth2 {
	width: 0;
	background: #fdfeff;
	transition: 0.5s;
	& .block-menu {
		padding: 0 0 60px 0;
		& li {
			border-radius: $sidebarRoundedBorderSize;
			height: $sidebarDivSize;
			width: $sidebarDivSize;
			& div {
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
		}
	}
}

.sidebarWidth3 {
	position: relative;
	width: 100vw;
	height: 100vh;
	float: left;
	overflow: scroll;
	scrollbar-width: none;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	& .block-menu {
		list-style: none;
		width: $sidebarWidth4;
		// padding: 0 0 60px 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1030;
		height: 100%;
		& .arrow-icon {
			position: absolute;
			margin-left: 2.5em;
			margin-top: 0.7em;
			color: white;
		}
		.menu-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transition: all 0.2s;
			position: relative;
			cursor: pointer;
			width: 100%;
			height: max-content;
			padding: 1rem 0;
			border-bottom: 1px solid var(--borderTop-color);

			// General Styling: Both Hover and Active State
			&:hover,
			&.active,
			&.active-menu {
				background-color: white;

				& .menu-icon {
					background-size: 30px !important;
				}

				& .menu-label {
					color: $label-color-dark-gray-2;
				}
				& .arrow-icon {
					color: $label-color-dark-gray-2;
				}
			}

			// General Styling: Menu Label
			& .menu-label {
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				text-align: center;
				white-space: nowrap;
				font-weight: 600;
				color: white;
			}

			// General Styling: Menu Icon
			& .menu-icon {
				width: 30px;
				height: 30px;
				background-size: 25px !important;
			}

			// General Styling: Side accent (side color when active)
			.side-accent {
				position: absolute;
				width: 4px;
				left: 0;
				height: 100%;
			}

			// PS Logo
			&.icon-home {
				background: no-repeat url(../../assets/icon/sidebar/logo.svg);
				background-origin: content-box;
				width: 5.231rem;
				height: 3.462rem;
				margin: 1.692rem auto 1.385rem auto;
				padding: 0;
				border-radius: 0;
				border: none;
			}

			&.topborder {
				border-top: 1px solid var(--borderTop-color);
			}

			// Profile Menu
			&.avatar-left {
				border-top: 1px solid var(--borderTop-color);

				&.active,
				&.active-menu {
					.side-accent {
						background-color: #cb2f3a;
					}
				}

				&.active,
				&.active-menu {
					& > .circleImageBorder {
						width: $sidebarDivSize * 1.15;
						height: $sidebarDivSize * 1.15;
					}
				}

				&:hover .circleImageBorder,
				&.active .circleImageBorder,
				&.active-menu .circleImageBorder {
					color: #cb2f3a;
				}

				.arrow-icon {
					right: -1.25rem;
					margin-top: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			// Dashboard Menu
			&.icon-dashboard {
				border-top: 1px solid var(--borderTop-color);

				& .menu-icon {
					background: no-repeat url(../../assets/icon/sidebar/dashboard.svg)
						center center;
					transition: 100ms ease-in-out;
				}

				&:hover,
				&.active {
					& .menu-icon {
						background: no-repeat
							url(../../assets/icon/sidebar/dashboard-active.svg) center center;
						transition: 100ms ease-in-out;
					}
				}

				&.active {
					.side-accent {
						background-color: #12bbd6;
					}
				}
			}

			// Logout Menu
			&.icon-logout {
				& .menu-icon {
					background: no-repeat url(../../assets/icon/sidebar/logout.svg) center
						center;
					transition: 100ms ease-in-out;
				}

				&:hover,
				&.active {
					& .menu-icon {
						background: no-repeat
							url(../../assets/icon/sidebar/logout-active.svg) center center;
						transition: 100ms ease-in-out;
					}
				}

				&.active {
					.side-accent {
						background-color: #12bbd6;
					}
				}
			}

			&:first-child.active::before {
				display: none;
			}
			&:last-child.active::after {
				display: none;
			}
		}
	}
}

.sidebarWidth4 {
	width: $sidebarWidth4;
	background: var(--blue-primary);
	& .block-menu {
		padding: 0px;
		margin: 0px;
		background: var(--blue-primary);
		// padding: 0 0 60px 0;
		& li {
			& div {
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
		}
	}
}

// Profile Menu
.profile-menu {
	position: absolute;
	top: 0rem;
	left: 8rem;
	width: 20rem;
	height: max-content;
	min-height: 4rem;
	background: #fff;
	box-shadow: 0.25rem 0.25rem 2rem rgba(black, 0.2);
	z-index: 1040;
	border-radius: 0.3rem;
	padding: 0.1rem;

	.triangle {
		position: absolute;
		left: -0.75rem;
		top: 1rem;

		width: 0;
		height: 0;

		border-right: 1.5rem solid white;
		border-top: 1.5rem solid transparent;
		border-bottom: 1.5rem solid transparent;
		z-index: -1;
	}

	.profile-item {
		width: 100%;
		padding: 0.769rem 1rem;
		background: none;
		outline: none;
		border: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		color: $label-color-dark-gray;
		font-weight: 500;
		transition: 200ms;

		&.highlight {
			background: $hover-color;

			&:hover:not(:disabled) {
				background: darken($color: $hover-color, $amount: 2);
			}
		}

		&.ay-inactive {
			background: $light-orange;

			&:hover:not(:disabled) {
				background: darken($color: $light-orange, $amount: 5);
			}
		}

		&.divider {
			border-bottom: 1px solid #d6dde9;
		}

		&:disabled {
			background-color: lighten($color: $label-color-gray, $amount: 15);
		}

		& * {
			font-size: 0.923rem;
		}

		&:hover:not(:disabled) {
			background: $hover-color;
		}

		.big-chevron {
			font-size: 1rem;
		}

		.text-academic-year,
		.text-language {
			font-size: 0.769rem;
			color: white;
			padding: 0.2rem 0.5rem;
			background-color: var(--label-color-blue);
			border-radius: 2rem;
			font-weight: 600;
		}

		.text-academic-year.not-active {
			background-color: $bg-second-orange;

			.tooltip-img {
				filter: brightness(0) saturate(100%) invert(100%);
			}
		}

		.circleImageBorder {
			color: $label-color-dark-gray-2;
		}
	}

	.profile-left {
		display: flex;
		align-items: center;
		flex: 1;
		min-width: 0;
		padding-right: 0.5rem;

		.info {
			display: flex;
			flex-direction: column;
			flex: 1;
			min-width: 0;

			div {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	.pr-icon {
		width: 1.154rem;
		height: 1.154rem;
		background-size: 1.154rem !important;
		margin-left: 0.5rem;
		margin-right: 1.5rem;

		&.icon-language {
			background: no-repeat url(../../assets/svg/language.svg) center center;
		}
	}
}

.academic-menu {
	position: relative;
	.profile-menu {
		position: absolute;
		top: 0;
		left: 8rem;
		width: 20rem;
		height: max-content;
		min-height: 1rem;
		background: #fff;
		box-shadow: 0.25rem 0.25rem 2rem rgba(black, 0.2);
		z-index: 1060;
		border-radius: 0.3rem;
		padding-bottom: 0;
	}
}

// sticky notes
.sticky-note {
	height: 100vh;
	position: fixed;
	background: #f2f7ff;
	right: 0;
	z-index: 999999;
	width: 500px;
	margin-right: -500px;
	box-shadow: -20px -3px 19px #6d809a0f, -11px 2px 20px 0px #ffffff85;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
	&:hover {
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		transition: all 0.5s ease;
		margin-right: 0;
	}
}

.btn-stycky-note {
	position: absolute;
	bottom: 20px;
	left: 0;
	margin-left: -40px;
	background: no-repeat url(../../assets/svg/writing.svg);
	background-size: 20px 100%;
	background-origin: content-box;
	background-color: $bg-icon;
	color: $bg-icon;
	height: 40px;
	width: 40px;
}

// end sticky notes

.setting {
	visibility: hidden;
}

.icon-collapse {
	background: no-repeat url(./../../assets/svg/arrow-up.png);
	background-origin: content-box;
	width: 65px;
	position: absolute;
	float: right;
	z-index: 999;
	right: 0;
	padding-right: 30px;
	height: 30px;
	margin-top: -9px;
}

.widget {
	padding: 1em;
	float: left;
	overflow: auto;
	height: $headerHeight;
	width: 100%;
	z-index: 2;
}

.noPadTop {
	padding-top: 0;
}

.borderbtm {
	border-bottom: 1.5px solid #e3edfd;
	margin-left: 0px;
	margin-right: 0px;
}

// // // // // // // // // // // // // // // // // // // // // // // //
.pictureInPictureBox {
	width: 500px;
	height: 250px;
	right: 5em;
	bottom: 4em;
	/* background: black; */
	position: fixed;
	z-index: 19;
	box-shadow: 0 0 0.5em 2px #bbb;
	& .videoPlayerActions {
		width: 500px;
		height: 2.3em;
		background: rgba(0, 0, 0, 0.5);
		display: none;
		bottom: 270px;
		z-index: 20;
		position: fixed;
	}
	&:hover .videoPlayerActions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		& .margin-1 {
			margin: 1em;
		}
	}
}
.fullPageVideoPlayer {
	position: relative;
	width: 100%;
	height: 75vh;
	// right: 5em;
	// bottom: 4em;
	/* background: black; */
	// position: fixed;
	left: 0;
	top: 0;
	&.descriptionBox {
		width: 100%;
		// height: 45vh;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.videoWidth {
			width: 100%;
		}
	}
	// z-index: 5000;
	// box-shadow: 0 0 0.5em 2px #bbb;
	@media (max-width: 1200px) {
		overflow-y: scroll;
	}
	@media (max-width: 800px) {
		height: max-content;
	}
	@media (max-height: 600px) {
		min-height: 75vh;
		height: max-content;
	}
	& div:first-child:hover ~ .videoPlayerActions,
	.videoPlayerActions:hover {
		opacity: 1;
	}
	& > div:first-child,
	& .videoWidth {
		border-radius: 0.8rem;
		overflow: hidden;
		@media (max-width: 1200px) {
			width: 100%;
			// height: 60vh;
			height: 100%;
		}
		@media (max-width: 460px) {
			width: 100%;
			height: 100%;
			// height: 30vh;
		}
		@media (max-height: 600px) {
			.video-js.vjs-fill {
				min-height: 75vh;
				height: max-content;
			}
		}
	}
	& .videoPlayerActions {
		top: 0;
		width: 70%;
		height: auto;
		background: linear-gradient(
			180deg,
			$label-color-dark-gray-2,
			rgba($label-color-dark-gray-2, 0.75) 35%,
			rgba($label-color-dark-gray-2, 0.1) 95%,
			transparent
		);
		display: flex;
		align-items: center;
		justify-content: space-between;
		opacity: 0;
		// bottom: 270px;
		z-index: 2;
		position: absolute;
		font-weight: 600;
		color: white;
		padding: 1rem;
		transition: 0.15s;
		border-radius: 0.8rem 0.8rem 0 0;
		.videoPlayerActionsTitle {
			font-size: 1.5rem;
			font-weight: 600;
		}
		@media (max-width: 1200px) {
			width: 100%;
		}
	}
	& .playlist {
		position: absolute;
		right: 0;
		top: 0;
		width: 30%;
		height: 100%;
		z-index: 1;
		padding: 0 1rem;

		display: flex;
		flex-direction: column;

		// background: $label-color-dark-gray-2;
		@media (max-width: 1200px) {
			position: relative;
			padding: 1rem;
			width: 100%;
			height: max-content;
		}

		.playlist-item {
			width: 100%;
			height: 8rem;
			display: flex;
			align-items: center;

			// &:not(:last-child) {
			// 	border-bottom: 1px solid white;
			// }
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}

		.playlist-content {
			flex: 1;
			overflow-y: auto;
			margin-right: -0.5rem;
			padding-right: 0.5rem;

			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
			}

			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}

		.playlist-header {
			position: sticky;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 0.5rem;
			.playlist-title,
			.fa {
				margin-left: 0;
				color: $label-color-dark-gray-2;
				font-size: 1.5rem;
			}
		}

		.videoThumbContainer {
			flex: 0 0 16rem;
			height: 8rem;
			min-height: 0;
			margin: 0;

			display: flex;

			&:hover .playClass {
				display: flex;
			}
		}

		.videoThumbClass,
		.blankThumb {
			width: 100%;
			height: 8rem;
		}

		.black-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(black, 0.7);
			display: none;
			border-radius: 0.75rem;
			&.show {
				display: block;
			}
		}

		.video-duration {
			font-weight: 600;
			color: white;
			background: rgba(black, 0.7);
			padding: 0.25rem 0.5rem;
			border-radius: 0.3rem;
			position: absolute;
			right: 0.5rem;
			bottom: 0.5rem;
		}

		.playlist-title {
			color: $label-color-dark-gray-2;
			margin-left: 1rem;
			font-size: 1.154rem;
			font-weight: 600;
			flex-grow: 1;
			line-height: 1.5;
			transition: 0.1s;

			&.videoTitleTruncate {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}

			&.active,
			&:hover {
				color: var(--label-color-blue);
			}
		}

		.playClass {
			display: none;
			&.show {
				display: flex;
			}
		}
	}
}
.searchBoxHeader {
	max-width: 15em;
	& .input-group-text {
		border-right: none;
	}
	& input {
		border-left: none;
		padding: 0.25em 0.4em 0.25em 0;
		height: auto;
		line-height: 1em;
	}
}

// new style added here

//////////////////////// style for notes

.nopadleft {
	padding-left: 0;
}

.nopadright {
	padding-right: 0;
}

.bg-ocean {
	background: $bg-container;
	padding: 0 15px;
}

.main-container {
	display: flex;
}

.border-main {
	border-top: 5px solid var(--blue-primary);
	border-right: 5px solid var(--blue-primary);
	border-top-left-radius: 20px;
}

.m-header {
	margin-left: 18px;
	margin-right: 13px;
}

.border-btm-main {
	border-top: 5px solid var(--blue-primary);
}

.nav-tabs {
	& .active {
		color: var(--label-color-blue);
	}
}

.nav-notes {
	border: 2px solid #fff;
	border-top-left-radius: 0.4rem;
	border-top-right-radius: 1.4rem;
	margin: 0 1px;
	font-weight: bold;
	color: $label-color-gray;
}

.tab-content {
	background: #fafcfd;
	// border-radius: 0 10px 10px 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	padding: 15px;
	&.book-tab {
		background: $bg-container;
		border-radius: 1em;
		height: 80vh;
		&.fullScreenPdfViewer {
			height: 100vh;
			width: 100vw;
			border-radius: 0;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		//If modal dialog comes down in body then it might not work
		&::-webkit-scrollbar,
		::-webkit-scrollbar {
			width: 12px;
			height: 12px;
		}

		&::-webkit-scrollbar-track,
		::-webkit-scrollbar-track {
			-webkit-border-radius: 0;
			border-radius: 0;
		}

		&::-webkit-scrollbar-thumb,
		::-webkit-scrollbar-thumb {
			-webkit-border-radius: 0;
			border-radius: 0;
			background: $label-color-blue;
		}
	}

	&.custom-summary {
		background: $bg-container;
		border-radius: 1em;
		height: 80vh;
		display: flex;
		justify-content: flex-start;
		overflow: auto;
		&.scrollCustomSummary {
			border-radius: 0;
			margin: 0;
		}
		&::-webkit-scrollbar,
		::-webkit-scrollbar {
			width: 12px;
			height: 12px;
		}

		&::-webkit-scrollbar-track,
		::-webkit-scrollbar-track {
			-webkit-border-radius: 0;
			border-radius: 0;
		}

		&::-webkit-scrollbar-thumb,
		::-webkit-scrollbar-thumb {
			-webkit-border-radius: 0;
			border-radius: 0;
			background: $label-color-blue;
		}
	}
}

.nav-custom-tab {
	background: $white-color;
	color: $label-color-gray;
	border-radius: 0.3rem;
	padding: 10px;
	margin: 7px 2px;
	text-decoration: none;
	font-weight: bold;
}

.nav-custom-tab:hover {
	background: #f2fdf8;
	color: #000;
	text-decoration: none;
}

.label-orange {
	border-left: 10px solid $label-color-orange;
	border-bottom: 2px solid #feaf437a;
}

.label-red {
	border-left: 10px solid $label-color-red;
	border-bottom: 2px solid #f0597463;
}

.label-blue-ocean {
	border-left: 10px solid $label-color-blue-ocen;
	border-bottom: 2px solid #36bfd74a;
}

.label-gray {
	border-left: 10px solid $label-color-gray;
	border-bottom: 2px solid #bec4cf4d;
}

.label-blue {
	border-left: 10px solid var(--label-color-blue);
	border-bottom: 2px solid var(--label-color-blue);
}

.label-green {
	border-left: 10px solid $label-color-green;
	border-bottom: 2px solid #1abf7238;
}

.label-purple {
	border-left: 10px solid $label-color-purple;
	border-bottom: 2px solid #7027a52d;
}

.label-dark-gray {
	border-left: 10px solid $label-color-dark-gray;
	border-bottom: 2px solid #67686952;
}

.label-pink {
	border-left: 10px solid $label-color-pink;
	border-bottom: 2px solid #ff057a27;
}

.btn-year {
	border-radius: 0px 0px 70px 70px;
	padding: 0 29px;
	background-color: var(--blue-primary);
	color: $color-white;
	width: 210px;
	height: 40px;
	font-weight: 600;
	margin-top: -15px;
	font-size: 20px;
}

// responsive navbar

@media (max-width: 425px) {
	.header {
		border-top-left-radius: 30px;
		padding: 0;
	}

	.bg-ocean {
		padding: 0 15px;
	}

	// .sidebarWidth2 {
	// 	width: 5px;
	// }

	// .contentWidth2 {
	// 	width: calc(100% - #{5px});
	// }

	// .sidebarWidth2 .block-menu li.icon-menu {
	// 	margin-top: 11px;
	// 	background-size: 42px 100%;
	// }

	.dropdown-title {
		z-index: 99999;
		text-align: center;
	}

	.workbook-label {
		margin-left: 20px;
		text-align: center;
	}

	.workbook-year-label {
		visibility: hidden;
		height: 0;
	}

	.header .setting {
		padding: 0;
		background: transparent;
	}

	.header .userDetailsHeader .picture {
		width: 35px;
		height: 35px;
		margin-right: 5px;
		margin-top: 3px;
	}

	.profile-picture {
		position: absolute;
	}

	// & .info {
	// 	visibility: hidden;
	// 	width: 1px;
	// }
}

.padding-l-r-1 {
	padding-left: 1em;
	padding-right: 1em;
}

@media only screen and (max-width: 450px) {
	.border-main {
		border-top: 0;
		border-right: 0;
		border-top-left-radius: 0;
	}

	.sidebarWidth4 {
		width: 0;
	}

	.sidebarWidth3 {
		display: none;
	}

	.contentWidth1 {
		width: calc(100% - 4.1em) !important;
	}

	.contentWidth2 {
		width: 100% !important;
	}

	// .widget {
	// height: 100vh !important;
	// width: 100vw !important;
	// }

	.border-btm-main {
		border-top: 0;
	}
}

.adminViewHeading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	z-index: 1000;
	width: 50vw;
	height: 40px;
	background: rgba(100, 100, 100, 0.7);
	color: white;
	border-radius: 3px;
	left: 25vw;
	padding: 0px 20px;
	overflow: hidden;
}

.adminViewText {
	color: white;
	font-size: 1.2rem;
	margin: 0;
}

.academic-year-profile-notice {
	width: 0.7rem;
	height: 0.7rem;
	background: $bg-second-orange;
	border-radius: 50%;
}

.mobile-header {
	display: none;
}

.mobile-menu {
	display: none;
}

.mobile-sidebar {
	display: none;
}

@media only screen and (max-width: 450px) {
	.mobile-sidebar {
		display: flex;
	}
	.sidebarWidth4 {
		display: none;
	}
	.contentWidth2 {
		width: 100%;
	}
	.sidebarContainer {
		display: none;
	}
	.border-main {
		border-top: 0 !important;
		border-right: 0 !important;
		border-top-left-radius: 0 !important;
	}
	.border-btm-main {
		border-top: 0 !important;
	}
	.mobile-header {
		display: flex;
		background-color: #034aa6;
		width: 100vw;
		height: 49px;
		position: relative;
		z-index: 1000;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}
	.widget {
		height: calc(100vh - 49px);
		border-radius: 0 !important;
		padding: 0.5rem;
	}
	.mobile-menu {
		display: flex;
		flex-direction: column;
		overflow: auto;
		height: calc(100vh - 49px);
		width: 100vw;
		position: absolute;
		z-index: 10000;
		background: #034aa6;
	}
	.iconDiv {
		display: flex;
		align-items: center;
		width: 35px;
	}
	.mobile-menu-item {
		display: flex;
		align-items: center;
		margin: 0px 13px;
		padding: 8px 10px;
		border-bottom: 1px solid #fff;

		.mobile-menu-label {
			color: white;
			margin-left: 10px;
		}
	}
	.profile-image {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 2px solid white;
		box-shadow: $Colorshadow;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-weight: 600;
		padding: 1rem;
	}
	.text-language {
		font-size: 0.8rem;
	}
	.menu-icon {
		width: 30px;
		height: 30px;
	}
	.menu-icon-active {
		width: 30px;
		height: 30px;
	}
	.mobile-profile-menu {
		display: flex;
		flex-direction: column;
		background-color: white;
		margin: 0px 13px;
		.mobile-profile-item {
			padding: 5px 9px;
		}
	}
	.profile-left {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		min-width: 0;
		padding-right: 0.5rem;

		.info {
			display: flex;
			flex-direction: column;
			flex: 1;
			min-width: 0;

			div {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	.mobile-menu-item-active {
		display: flex;
		align-items: center;
		margin: 0px 13px;
		padding: 8px 10px;
		border-bottom: 1px solid #fff;
		background-color: white;
		.mobile-menu-label {
			color: #034aa6;
			margin-left: 10px;
		}
	}

	.mobile-profile-menu {
		.pr-icon {
			width: 1.154rem;
			height: 1.154rem;
			background-size: 1.154rem !important;
			margin-left: 0.5rem;
			margin-right: 1.5rem;

			&.icon-language {
				background: no-repeat url(../../assets/svg/language.svg) center center;
			}
		}
	}

	.mobile-icon-dashboard {
		.menu-icon {
			background: no-repeat url(../../assets/icon/sidebar/dashboard.svg) center
				center;
		}
		.menu-icon-active {
			background: url(../../assets/icon/sidebar/dashboard-active.svg) center
				center;
		}
	}

	.mobile-icon-logout {
		.menu-icon {
			background: no-repeat url(../../assets/icon/sidebar/logout.svg) center
				center;
		}
		.menu-icon-active {
			background: url(../../assets/icon/sidebar/logout-active.svg) center center;
		}
	}

	.adminViewHeading {
		width: 100vw;
		left: 0;
	}
}
