@import "./../../colorvariable.scss";

.regist-component {
  background: white;
  border-top-left-radius: 21px;

  .regist-content {
    flex: 1;
    height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      height: max-content;
      min-height: 100vh;
    }
  }

  .btn-cross {
    font-size: 3rem;
    line-height: 3rem;
    color: $label-color-dark-gray;

    &.small {
      font-size: 1.5rem;
      line-height: 1rem;
    }

    &:focus {
      outline: 1px solid black;
    }
  }

  .btn-primary {
    padding: 0.615rem 1.154rem;
    font-size: 1.077rem;
    font-weight: 500;
    border-radius: .385rem;
  }

  .pill-school-name {
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;
    padding-right: 0.25rem;
    border: 1px solid #eaeaef;
    background: #faf9f9;
    color: $label-color-dark-gray;
  }

  .gap-2 {
    gap: 0.5rem;
  }

  .batch-content {
    height: 80vh;

    &-noContent {
      & p {
        font-size: 1.692rem;
      }
    }
  }

  & h5.header {
    color: $active;
    font-size: 1.538rem;
    margin-bottom: 0rem;
  }
}

.general-form.regist-form {
  .form-group {
    padding-top: 0;
    width: 100%;
    margin-bottom: 0;
  }

  label {
    font-size: 1.154rem;
    color: $label-color-dark-gray;
    font-weight: 500;
    margin-bottom: 0.385rem;
  }

  .gap-20 {
    gap: 1.538rem;
  }

  .timepickerlike {
    padding: 0.5em 3.5em 0.5em 1em;
    background-image: url("../../assets/icon/calendar.png"),
      linear-gradient(to right, var(--label-color-blue), var(--label-color-blue));
    background-position: calc(100% - calc(3rem / 2) + 8px) 50%, 100% 0;
    // Sizing White Arrow
    background-size: 16px 16px, 3rem 100%;
    background-repeat: no-repeat;

    &:disabled {
      background-image: url("../../assets/icon/calendar.png"),
        linear-gradient(to right, #A1C4F4, #A1C4F4);
    }
  }

  .custom-switch .custom-control-label {
    transform: scale(1.15);
  }

  & .custom-checkbox,
  & .custom-radio {
    &.custom-control {
      padding-left: 0;
    }

    .checkbox-label,
    .radio-label {
      font-weight: 500;
      color: $label-color-dark-gray;
      margin-left: 0.5rem;
      cursor: pointer;
    }

    .empty-label {
      height: 0;

      &.red-border::before {
        border-color: red;
      }
    }

    .custom-control-label::before {
      width: 20px;
      height: 20px;
      border: 1px solid $border-color;
      top: 0;
      left: 0;
    }

    .custom-control-input:checked~.custom-control-label::before {
      width: 20px;
      height: 20px;
      color: #fff;
      border-color: var(--label-color-blue);
      background-color: var(--label-color-blue);
    }

    .custom-control-label::after {
      width: 20px;
      height: 20px;
      color: #fff;
      border-color: transparent;
      background-color: transparent;
      top: 0;
      left: 0;
      position: relative;
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .custom-control-label.partial::after {
      font-family: FontAwesome;
      content: "\f068";
      background-image: none;
      font-weight: normal;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .custom-control-input:checked~.custom-control-label::after {
      color: #fff;
      border-color: transparent;
      background-color: transparent;
    }

    .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
      color: #fff;
      border-color: $label-color-gray;
      background-color: $label-color-gray;
    }
  }

  .data-val-textarea {
    height: auto;
    padding: 0.5rem;
  }

  .textarea-with-count {
    position: relative;
    width: 100%;
  }

  .textarea-cnt {
    position: absolute;
    right: 0.5rem;
    bottom: 0.25rem;
    color: $label-color-gray;
    font-weight: 500;
  }
}

.regist-form-container {
  padding: 1.154rem 1.923rem;
  background-color: white;
  border-radius: 0.615rem;
  box-shadow: 0px 3px 3px #d5deec29;
  border: 1px solid $border-color;
  margin-bottom: 1.154rem;
}

.regist-form-header {
  font-size: 1.154rem;
  font-weight: 700;
  margin-bottom: 1.154rem;
}

.regist-form-row {
  display: flex;
  gap: 3.692rem;

  &.flex-column {
    gap: 2.154rem;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
}