@import "./../../colorvariable.scss";

.profile-component {
	
	&.widget {
		padding: 1.538rem !important;
	}

	.p-back {
		margin-bottom: 0;
		margin-right: 1.231rem;
		font-size: 1.538rem;
		color: $label-color-dark-gray;
		transition: 0.1s;

		&:hover {
			color: $label-color-gray;
		}
	}

	.p-header {
		margin-bottom: 0;
		font-weight: bold;
		font-size: 1.538rem;
		color: $label-color-dark-gray-2;
	}

	.p-scroll {
		// Purpose: Content, scroll this thing only
		// You need to first set flex column in the parents
		min-width: 0;
		overflow: auto;

		// Hide scrollbar
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

.mock-field {
	.mf-item {
		width: 25rem;
		margin-right: 1rem;
		margin-bottom: 1.538rem;

		label {
			font-size: 0.846rem;
			font-weight: 500;
			color: #a0a2a5;
			margin-bottom: 0.3rem;
		}

		.mf-field {
			font-size: 1.077rem;
			font-weight: 500;
			color: $label-color-dark-gray;
			padding-bottom: 0.5rem;
			border-bottom: 1px solid $bg-container;
			width: 100%;
		}

		.mf-info {
			margin-left: auto;
			font-style: italic;
			color: #a0a2a5;
			font-size: 0.846rem;
			background: white;
			padding-left: 1rem;
		}
	}

	.mb-md-0 {
		@media (min-width: 768px) {
			margin-bottom: 0;
		}
	}
}


// Individual Styling
.profile-container {
	width: 100%;
	border-radius: 0.5rem;
	border: 2px solid white;
	box-shadow: 0 0 3px #d5deec;
	margin-bottom: 1.538rem;

	&.active {
		flex-grow: 1;
	}

	.pc-header {
		padding: 0.9rem 1.538rem;
		font-weight: bold;
		font-size: 1.231rem;
		color: $label-color-dark-gray-2;
		border-radius: 0.5rem;
		position: relative;
		//added
		border: none;

		&.first {
			background: $light-dark-green;
		}
		&.second {
			background: #e6fff4;
		}
		&.third {
			background: $light-green;
		}

		.arrow {
			position: absolute;
			right: 1.538rem;
			top: 50%;
			transform: translateY(-50%);
			padding: 0px;
			width: 0;
			height: 0;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-top: 0;
			border-bottom: 11px solid #bec4cf;

			&.open {
				border-left: 9px solid transparent;
				border-right: 9px solid transparent;
				border-top: 11px solid #bec4cf;
				border-bottom: 0;
			}
		}
	}

	.pc-content {
		border-radius: 0 0 0.5rem 0.5rem;
		background: white;
		padding: 1.154rem 1.538rem;
		transition: 500ms;
		width: 100%;
		word-wrap: break-word;
	}
}
