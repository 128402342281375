@import "./../../colorvariable.scss";
@import "./../../mixin.scss";

.not-found-page {
  width: 100vw;
  height: 100vh;
  width: 100dvw;
  height: 100dvh;

  @include flex-all-center;

  .no-content-component {
    text-shadow: 0 0 4px rgba(black, 0.16);
  }
}