@import "../../../colorvariable.scss";

.lg-w80 {
  padding: 0em 6em;
}

.confirm-box .btn {
  // font-weight: 600;
}
.confirm-box .modal-content {
  // width: auto;
  border-radius: 0.75rem;
}

.confirm-box .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.75rem;
}

.confirm-box .delete-title {
  font-size: 30px;
  color: #232230;
  font-weight: 700;
  padding: 30px 0px;
}

.confirm-box .delete-subtitle {
  font-size: 20px;
  color: #676869;
  padding-bottom: 46px;
}

.confirm-box .delete-subtitle-small {
  font-size: 1.5rem;
  color: #676869;
  padding-bottom: 46px;
}

.confirm-box .noDeleteMeeting {
  background-color: #f2f2f2;
  padding: 1rem 2.7rem;
  color: #676869;
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: 4rem;
  box-shadow: 0 3px 6px rgba(black, 0.16);
  transition: all 0.2s;
  border-radius: 0.38rem;

  &:focus {
    transform: translateY(3px);
    box-shadow: none;
  }
}

.confirm-box .yesDeleteMeeting {
  background-color: red;
  padding: 1rem 2.7rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  box-shadow: 0 3px 6px rgba(black, 0.16);
  transition: all 0.2s;
  border-radius: 0.38rem;

  &:focus {
    transform: translateY(3px);
    box-shadow: none;
  }
}

.confirm-box .confirm-blueBtn .btn.yesDeleteMeeting {
	background-color: $label-color-blue;
}
