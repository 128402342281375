@import "./../../colorvariable.scss";

.dash {
  background: $bg-White;
  border-top-left-radius: 16px;

  & h5 {
    color: $active;
    font-size: 1.538rem;
    margin-bottom: 0rem;
  }

  &-logo {
    width: auto;
    height: 2.308rem;
    margin-left: 1rem;
  }

  &-header {
    text-align: center;
    margin: 2rem 0rem;
    color: $label-color-dark-gray-2;

    & h1 {
      font-weight: 700;
      font-size: 2.615rem;
    }

    & h3 {
      font-weight: 600;
      font-size: 1.538rem;
    }
  }

  &-content {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
    }

    &-body {
      width: 55%;
      display: flex;
      gap: 1.231rem;
      flex-direction: column;

      & .dcBox {
        display: flex;
        align-items: center;
        border-radius: 1.154rem;
        border: 1px solid #C9E0FB;
        transition: all .1s ease-out;

        &.hover {
          background-color: $active;
        }

        &-img {
          padding: 5px;
          max-width: 120px;
          margin-right: 4.308rem;

          @media screen and (max-width: 768px) {
            margin-right: 0rem;
          }

          & img {
            width: 100%;
            height: auto;

            @media screen and (max-width: 768px) {
              width: 80%;
            }
          }
        }

        &-text {
          gap: .385rem;
          display: flex;
          margin: 2.462rem 0rem;
          flex-direction: column;

          & h4 {
            color: #034AA6;
            font-weight: 700;
            font-size: 1.538rem;
          }

          & h5 {
            color: black;
            font-weight: 600;
            font-size: 1.231rem;

            @media screen and (max-width: 768px) {
              font-size: 16px;
            }
          }

          &.hover {
            margin: 2.462rem;
            margin-left: 3.077rem;

            & h4,
            & h5 {
              color: $bg-White;
            }
          }
        }

        &-btn {
          margin-left: auto;
          margin-right: 3.077rem;
          
          @media screen and (max-width: 768px) {
            padding-left: 1.538rem;
          }

          & button {
            font-weight: 600;
            color: $bg-White;
            font-size: 1.231rem;
            padding: .769rem 2rem;
            border-radius: .462rem;
            background-color: $active;
            border: 1px solid $active;

            &.hover {
              color: $active;
              background-color: $bg-White;
            }
          }
        }

        &-note {
          color: red;
          font-size: 1rem;
          font-weight: 600;
          padding-top: .462rem;

          &.hover {
            color: white;
          }
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &-status {
      width: 45%;
      padding: 0rem 3.692rem;

      @media screen and (max-width: 768px) {
        padding: 0rem;
        margin-top: 4rem;
      }

      & .statBox {
        width: 100%;
        height: 100%;
        display: flex;
        max-height: 30rem;
        align-items: center;
        border-radius: 1rem;
        flex-direction: column;
        justify-content: center;
        background-color: #FFF9F1;
        box-shadow: 4px 6px 4px #EDEDED;

        @media screen and (max-width: 768px) {
          max-height: 34rem;
        }

        &.continueApply {
          background-color: #F9F9F9;
        }

        &.inReview {
          background-color: #FFF9F1;
        }

        &.approved {
          background-color: #F6FFFB;
        }

        &.rejected {
          background-color: #FEF7F7;
        }

        & h4 {
          font-weight: 600;
          color: #000000;
          font-size: 1.846rem;
          margin-bottom: 2.154rem;
        }

        &-main {
          width: 28rem;
          display: flex;
          gap: 1.154rem;
          flex-direction: row;
          margin-left: 5.385rem;
          padding-bottom: 2.308rem;

          &-left,
          &-right {
            width: 100%;
            display: flex;
            gap: 1.308rem;
            max-width: 50%;
            flex-direction: column;
            justify-content: center;
          }

          &-left > p {
            font-weight: 500;
            font-size: 1.154rem;
            margin-bottom: 0rem;
            color: $label-color-dark-gray;
          }

          &-right > p {
            font-weight: 700;
            font-size: 1.154rem;
            margin-bottom: 0rem;
          }
        }
      }

      & .statBtn {
        display: flex;
        margin-top: 2rem;
        justify-content: center;

        & button {
          font-weight: 600;
          border-radius: 8px;
          font-size: 1.231rem;
          padding: .923rem 2rem;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &-spinner {
    position: absolute;
    top: 13.5em;
    width: 93.8vw;
    height: 80vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;

    @media screen and (max-width: 450px) {
      top: 18em;
      right: 1em;
    }
  }

  & .withStatus {
    width: 100%;
  }
}

.blankBoxContainer {
  height: 100%;
  border-radius: 0.5em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.hundredPheight {
  height: 100%;
}

.upcomingEvents {
  & .upcomingEventsHeading {
    font-size: 1.25em;
    padding: 0.35em;
    font-weight: 600;
    margin-bottom: 0.4em;
    display: flex;
    align-items: center;

    & img {
      margin-right: 0.5em;
    }
  }

  & .upcomingEventDayDate {
    background: $fillLightGray;
    padding: 0.35em;

    & .dayName {
      text-align: left;
      padding-left: 1em;
      font-weight: 500;
    }

    & .dateValue {
      text-align: right;
      padding-right: 1.5em;
      font-weight: 500;
    }
  }

  & .eventName {
    & .greenBead {
      background: $color-green;
      width: 0.6em;
      height: 0.6em;
      margin-right: 1em;
      margin-top: 0.45em;
      float: left;
      border-radius: 100%;
    }

    font-weight: 600;
    margin-bottom: 0.4em;
  }

  & .eventTimeHeading {
    & img {
      margin-right: 1em;
    }

    font-weight: 600;
    font-size: 0.9em;
    margin-bottom: 0.3em;
  }

  & .eventTime {
    padding-left: 3em;
    font-size: 0.8em;
    margin-bottom: 0.3em;
  }

  & .eventDescription {
    padding-left: 3em;
    font-size: 0.8em;
  }

  & .eventDetails {
    padding: 0 0.35em;
    border-radius: 0.25em;
    margin-bottom: 1em;
    background: white;
    border: 1px solid #f2f2f2;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.postNowButton {
  font-size: 1em;
  padding: 0.15em 0.5em;
  background: $lightGrayActionColor;
  color: white;
  font-weight: 600;
}

.noPadRight {
  padding-right: 0;
}

.selectOneFlexBox {
  display: flex;
  justify-content: space-between;
}

.centerFlex {
  display: flex;
  align-items: center;
}

.labelColors {
  margin: 0;
  list-style-type: none;

  & li {
    float: left;
    width: 3em;
    height: 2.5em;
    border-radius: 0.25em;
    margin-right: 0.5em;
    cursor: pointer;

    &.Teal {
      background: $labelOne;
    }

    &.Violet {
      background: $labelTwo;
    }

    &.Green {
      background: $labelThree;
    }

    &.Red {
      background: $labelFour;
    }

    &.Neon {
      background: $labelFive;
    }

    &.selectedColor {
      background-image: url("./../../assets/icon/dashboard/tick.png");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.checkboxLabel {
  margin-left: 1.5em;
  cursor: pointer;

  &::before {
    border-radius: 0.15em;
  }
}

.greenActionButton,
.lightGrayActionButton,
.blueActionButton {
  float: right;
  color: white;
  font-weight: 600;
  line-height: 1em;
  padding: 0.4em 2.5em;
  border: none;
  margin: 0 0.15em;
}

.greenActionButton {
  background: $greenActionColor;
}

.blueActionButton {
  background: var(--themeBlue);
}

.lightGrayActionButton {
  background: $lightGrayActionColor;
}

.margin-t-b-4 {
  margin-top: 4em;
  margin-bottom: 4em;
}

.announcementsContainer {
  background: white;
}

.sortBySection {
  width: max-content;

  div,
  select {
    float: left;
  }
}

.announcementSearch {
  max-width: 55%;
}

.addAnnouncementIcon {
  float: right;
  width: 1.5em;
  margin-top: 0.8em;
}

.filterIconClass {
  float: right;
  margin-top: 0.9em;
  margin-right: 0.5em;
}

.dashedBorder {
  border: 2px dashed $borderColor;
  padding-top: 1em;
  padding-bottom: 1em;
  border-radius: 0.5em;

  & .browseFileButton {
    color: $borderColor;
    border: 1px solid $borderColor;
    background: white;
  }
}

.announcementEditorClassName {
  background: $white-color;
  position: relative;
  min-height: 7em;
  max-height: 7em;
  border: 1px solid $borderColor;
}

.floatingFilterBox {
  position: absolute;
  background: white;
  z-index: 1;
  box-shadow: 0 0 3px #ddd;
  border-radius: 0.5em;
  border: 2px solid #eee;
  padding: 0.5em 0 1em 0;
  top: 2.5em;

  & label {
    margin-bottom: 0;
  }

  & select {
    margin-bottom: 1em;
  }
}

.mt-10 {
  margin-top: 10px;

  & button {
    margin: 0 5px;
  }
}

.browseButton {
  padding: 0.25em 1.5em;
}

@media only screen and (max-width:450px) {
  .sticky-top {
    z-index: 100 !important;
  }
}