@import "./../../../colorvariable.scss";

.change-language-box {
  .modal-body {
    padding: 1.923rem;
  }
  .modal-content {
    border-radius: .75rem;
  }
  .content {
    padding-top: 0.5rem;
    padding-bottom: 2.308rem;
  }
  .select-like {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.154rem 0;
    color: $label-color-dark-gray;
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;
    transition: .2s;
    font-weight: 500;

    .check-like {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $label-color-gray;
      transition: .2s;
      color: white;
      font-size: 0.769rem;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.769rem;
    }

    &.checked {
      .check-like {
        border: none;
        background: var(--label-color-blue);
      }
      // border-bottom: 1px solid $label-color-blue;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .delete-title {
    font-size: 2.3em !important;
    color: $label-color-dark-gray-2;
    font-weight: 700;
    padding: 2.3rem 0;
  }
  .no-btn {
    background-color: transparent;
    color: var(--label-color-dark-blue);
    padding: 0;
    font-weight: 400;
  }
  .yes-btn {
		background: linear-gradient(90deg, var(--label-color-blue) 0%, #195eba 100%);
    padding: 1rem 3rem;
    color: white;
    font-weight: 400;
    transition: all .2s;
    border-radius: 5rem;

    &:hover {
      background: linear-gradient(90deg, var(--label-color-dark-blue) 0%, var(--label-color-dark-blue) 100%);
    }
  }
}
