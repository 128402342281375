@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap");
@import "./colorvariable.scss";
@import "./bootstrap.scss";

:root {
	--gray-hue: 190;
	--blue-hue: 209;
	--purple-hue: 225;
	--red-hue: 0;
	--yellow-hue: 56;
	--green-hue: 131;
	--main-hue: var(--gray-hue);
	// themeChanger
	--bg-dark-blue: #204f8d;
	--blue-primary: #034aa6;
	--themeBlue: #488bff;
	--label-color-blue: #4f92ea;
	--label-color-dark-blue: #056cf2;
	--borderTop-color: #0959c1;
	--borderBottom-color: #0959c1;
	--blue-assessment: #007bff;
	--label-color-gray: #bec4cf;
	--label-color-dark-gray: 103, 104, 105;
	--label-color-light-green: #d5e7fc;
	--label-color-background-summary: #f2f7ff;
	--background-light-red: #fef5f5;
	width: 100%;
}

:host {
	font-family: "Montserrat", sans-serif;
	color: #333;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	word-break: initial;
	font-family: "Montserrat", sans-serif;
	font-size: 13px;
}

::-webkit-scrollbar {
	// width: 0.3em;
	// height: 0.3em;
	width: 0px;
	height: 0px;
	background: transparent;
}

::-webkit-scrollbar-corner {
	width: 0em;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 0.3em 0.3em rgba(255, 255, 255, 0);
	// -webkit-border-radius: 0.3em;
	// border-radius: 0.3em;
}

::-webkit-scrollbar-thumb {
	// -webkit-border-radius: 0.3em;
	// border-radius: 0.3em;
	// background: #e6e6e6;
	background: $label-color-blue;
	-webkit-box-shadow: inset 0 0 0.3em 0.3em rgba(255, 255, 255, 0);
}

body {
	font-family: "Nunito", sans-serif;
	position: relative;
	margin: 0;
	padding: 0;
	font-size: 16px;
	background: $hover-color;
	overflow: visible !important;
}

.form-control {
	padding: 0.1rem 0.75rem;
	height: 100%;
}

.breadcrumb {
	background-color: transparent;
}

// Custom Select Option
.select-filter {
	border-color: #b7d6ff;
	padding: 0.5em 3.5em 0.5em 1em;
	color: gray;
	height: 3.2em;
	background-color: white;
	border-radius: 0.438em;
	display: inline-block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-flex-item-align: center !important;
	align-self: center !important;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	// Create Background and White Arrow
	background-image: linear-gradient(45deg, transparent 50%, white 50%),
		linear-gradient(135deg, white 50%, transparent 50%),
		linear-gradient(to right, var(--label-color-blue), var(--label-color-blue));

	// Positioning Background and White Arrow
	background-position: calc(100% - 20px) 50%, calc(100% - 14px) 50%, 100% 0;
	// Sizing White Arrow
	background-size: 6px 6px, 6px 6px, 3em 100%;
	background-repeat: no-repeat;
}

.select-filter:focus {
	background-image: linear-gradient(45deg, white 50%, transparent 50%),
		linear-gradient(135deg, transparent 50%, white 50%),
		linear-gradient(to right, var(--label-color-blue), var(--label-color-blue));
	background-position: calc(100% - 1em) 50%, calc(100% - 1.4em) 50%, 100% 0;
	background-repeat: no-repeat;
	border-color: grey;
	outline: 0;
}

.select-filter-sm {
	color: $label-color-dark-gray;
	font-weight: 500;
	padding: 0.5em 3.5em 0.5em 1em;
	border-radius: 6px;
	display: inline-block;
	line-height: 1.5em;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	background: white;
	outline: none;
	border: 1px solid #b7d6ff;

	// Create Background and White Arrow
	background-image: linear-gradient(45deg, transparent 50%, white 50%),
		linear-gradient(135deg, white 50%, transparent 50%),
		linear-gradient(to right, var(--label-color-blue), var(--label-color-blue));

	// Positioning Background and White Arrow
	background-position: calc(100% - 1.3em - 0.15em) 50%,
		calc(100% - 1.3em + 0.15em) 50%, 100% 0;
	// Sizing White Arrow
	background-size: 0.3em 0.3em, 0.3em 0.3em, 2.9em 2.7em;
	background-repeat: no-repeat;
}

.select-filter-sm:focus {
	// Create Background and White Arrow
	background-image: linear-gradient(45deg, white 50%, transparent 50%),
		linear-gradient(135deg, transparent 50%, white 50%),
		linear-gradient(to right, var(--label-color-blue), var(--label-color-blue));

	// Positioning Background and White Arrow
	background-position: calc(100% - 1.3em + 0.15em) 50%,
		calc(100% - 1.3em - 0.15em) 50%, 100% 0;
	// Sizing White Arrow
	background-size: 0.3em 0.3em, 0.3em 0.3em, 2.9em 2.7em;
	background-repeat: no-repeat;
	border-color: grey;
	outline: 0;
}

.da-multi-select.disabled,
.select-filter:disabled {
	font-weight: 500;
	background-color: white;
	background-image: linear-gradient(45deg, transparent 50%, #bec4cf 50%),
		linear-gradient(135deg, #bec4cf 50%, transparent 50%),
		linear-gradient(to right, #f2f7ff, #f2f7ff);
}

.select-filter-sm:disabled {
	font-weight: 500;
	background-color: white;
	background-image: linear-gradient(45deg, transparent 50%, #bec4cf 50%),
		linear-gradient(135deg, #bec4cf 50%, transparent 50%),
		linear-gradient(to right, #f2f7ff, #f2f7ff);
}

.select-filter-sm-disabled {
	border: 1px solid #bec4cf;
	border-radius: 6px;
	display: inline-block;
	line-height: 1.5em;
	height: 31px;
	padding: 0.5em 3.5em 0.5em 1em;
	margin-left: 10px;
	padding-right: 40px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	margin-top: -5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	// Create Background and White Arrow
	background-image: linear-gradient(45deg, transparent 50%, #bec4cf 50%),
		linear-gradient(135deg, #bec4cf 50%, transparent 50%),
		linear-gradient(to right, #f2f7ff, #f2f7ff);

	// Positioning Background and White Arrow
	background-position: calc(100% - 15px) calc(0.8em + 2px),
		calc(100% - 8px) calc(0.8em + 2px), 100% 0;
	// Sizing White Arrow
	background-size: 5px 5px, 5px 5px, 2em 2.5em;
	background-repeat: no-repeat;
}

.select-filter-disabled {
	border: 1px solid $select-filter-border;
	border-radius: 7px;
	display: inline-block;
	line-height: 1.5em;
	height: 38px;
	padding: 0.5em 3.5em 0.5em 1em;
	margin-left: 10px;
	padding-right: 40px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	margin-top: -5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
	color: #676869;

	// Create Background and White Arrow
	background-image: linear-gradient(45deg, transparent 50%, #bec4cf 50%),
		linear-gradient(135deg, #bec4cf 50%, transparent 50%),
		linear-gradient(to right, #f2f7ff, #f2f7ff);

	// Positioning Background and White Arrow
	background-position: calc(100% - 20px) calc(1em + 2px),
		calc(100% - 14px) calc(1em + 2px), 100% 0;
	// Sizing White Arrow
	background-size: 6px 6px, 6px 6px, 3em 3em;
	background-repeat: no-repeat;
}

.select-filter-disabled:disabled {
	background-color: #ffffff;
	color: #bec4cf;
}

.select-filter-sm-disabled:disabled {
	background-color: #ffffff;
}

.display-none {
	display: none;
}

.floatLeft {
	float: left;
}

.mrgntp-m5 {
	margin-top: -4.5em;
}

.pageHeading {
	z-index: 1;
	margin-top: -8px;
	font-size: 1.75rem;
	margin-bottom: 0;
	font-weight: 600;
	color: var(--bg-dark-blue);
}

.pageHeadingBreadcrumbs {
	z-index: 1;
	font-size: 1.385rem;
	font-weight: 600;
	color: var(--label-color-blue);
}

.border-heading {
	border-bottom: 2px solid #e3edfd;
	margin-bottom: 20px;
}

.text-truncate {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.general-form {
	label {
		font-weight: 600;
		color: $label-color-dark-gray;
		margin-bottom: 0;

		&.bigger {
			font-size: 1.154rem;
			font-weight: 600;
			color: $label-color-dark-gray-2;
		}
	}

	.form-group {
		padding-top: 0;
	}

	.red-text {
		color: red;
		font-style: italic;
		font-weight: 500;
	}

	.red-asterisk {
		color: red;
		font-weight: 600;
		font-size: inherit;
	}

	.form-control {
		border-color: $border-color;
		font-weight: 500;
		height: 3.846rem;
		padding: 0 0.769rem;
		border-radius: 0.5rem;
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: #f2f7ff;
		opacity: 1;
	}

	.form-control::placeholder {
		color: $label-color-gray;
	}

	.form-group {
		margin-bottom: 2rem;
	}

	// FORM STYLING
	.select-question-field {
		border-color: $border-color;
		padding: 0.5em 4em 0.5em 1em;
		color: gray;
		height: 3.2em;
		background-color: white;
		border-radius: 0.438em;
		display: inline-block;
		box-sizing: border-box;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-flex-item-align: center !important;
		align-self: center !important;

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		// Create Background and White Arrow
		background-image: linear-gradient(45deg, transparent 50%, #f2f2f2 50%),
			linear-gradient(135deg, #f2f2f2 50%, transparent 50%),
			linear-gradient(to right, var(--label-color-blue), var(--label-color-blue));

		// Positioning Background and White Arrow
		background-position: calc(100% - 1.4em) calc(1em + 0.071em), calc(100% - 1em) calc(1em + 0.071em), 100% 0;
		// Sizing White Arrow
		background-size: 0.429em 0.429em, 0.429em 0.429em, 3em 4em;
		background-repeat: no-repeat;

		&.placeholder {
			color: $label-color-gray;

			option {
				color: $label-color-dark-gray-2;
			}
		}
	}

	.custom-input-upload {
		position: relative;
		margin-top: 1.5rem;

		.custom-upload-label {
			position: absolute;
			min-height: 1.5rem;
			// height: 100%;
			height: calc(3.5rem - 2px);
			display: flex;
			align-items: center;
			padding: 0.8em;
			background: $label-color-blue;
			color: white;
			border: none;
			border-right: 1px solid $border-color;
			border-radius: 0.5rem 0 0 0.5rem;
			cursor: pointer;
			margin: 0;
			margin: 1px 0 0 1px;
		}

		.form-control-file {
			display: block;

			width: 100%;
			line-height: 3rem;
			height: 3.5rem !important;

			border: 1px solid $border-color;
			border-radius: 0.5rem;

			padding-left: 7rem;
			padding-right: 2rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			color: $label-color-dark-gray;
			font-style: italic;
			font-weight: 500;
		}

		input[type="file"] {
			display: none;
		}

		::-webkit-file-upload-button {
			background: transparent;
			color: transparent;
			border: none;
			padding: 0;
		}

		&.disabled {
			.custom-upload-label {
				cursor: auto;
				color: $label-color-gray;
			}

			.form-control-file {
				background-color: $bg-container;
			}
		}
	}

	textarea.form-control {
		resize: none;
		width: 100%;
		height: 7.692rem;
		border-color: $border-color;
		padding: 0.769rem;
	}

	.red-border {
		border-color: red !important;
	}

	.btn-cancel {
		color: $label-color-dark-gray;
		font-weight: 600;
		padding-left: 0 !important;
	}

	.image-preview {
		width: 2rem;
		height: 2rem;
		object-fit: cover;
		border-radius: 0.5rem;
		border: 1px solid #c8c8c9;
		box-shadow: 1px 1px 0 rgba(#7e7e7e, 0.16);

		&.no-styling {
			border-radius: 0;
			border: none;
			box-shadow: none;
		}
	}

	.image-preview-label {
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-weight: 500;
		color: $label-color-dark-gray;
	}

	.section-header {
		font-weight: 600;
		font-size: 1.154rem;
		color: $label-color-dark-gray;
		padding: 0.5rem 0.769rem;
		border-radius: 0.3rem 0.3rem 0 0;
		background-color: $light-dark-green;
		width: max-content;
	}

	.section-header-divider {
		width: 100%;
		border-bottom: 1px solid #c9e0fb;
	}

	.rdtPickerOpenUpwards .rdtPicker {
		top: auto;
		bottom: 100%;
	}
}

.da-custom-radio {
	display: flex;
	position: relative;

	label {
		font-weight: 500;
		font-size: 1.077rem;
		color: $label-color-dark-gray;
	}

	.custom-control-label::before {
		color: #fff;
		border-width: 2px;
		border-radius: 50%;
	}

	.custom-control-input.red-border~.custom-control-label::before {
		color: #fff;
		border-color: $label-color-red;
		background-color: transparent;
	}

	.custom-control-label,
	.custom-control-label::after,
	.custom-control-label::before {
		cursor: pointer;
		top: 0;
		left: 0;

		// We have to use px due to chrome cannot render decimal pixels
		width: 20px;
		height: 20px;
	}

	.custom-control-input:checked~.custom-control-label::before {
		color: #fff;
		border-color: var(--label-color-blue);
		background-color: transparent;
	}

	.custom-control-input:checked~.custom-control-label::after {
		background-color: var(--label-color-blue);
		background-image: none;
		border-radius: 50%;

		// We have to use px due to chrome cannot render decimal pixels
		width: 10px;
		height: 10px;

		margin-left: 5px;
		margin-top: 5px;
	}
}

.nav.nav-tabs-custom {
	.nav-tabs-custom-left {
		min-width: 0;
		display: flex;
	}

	& .nav-custom {
		background: #ffffff;
		border: 1px solid $border-color;
		border-bottom: none;
		text-align: center;
		font-weight: 600;
		font-size: 1.154rem;
		padding: 0.5em 1.2em;
		border-top-left-radius: 0.6em;
		border-top-right-radius: 0.6em;
		color: $label-color-dark-gray;
		margin-right: 0.5em;
		transition: all 0.2s;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.nav-q-count {
			font-size: 0.923rem;
			color: white;
			background: var(--label-color-blue);
			font-weight: 600;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			margin-left: 0.769rem;
			transition: 0.2s;
		}

		&:hover {
			cursor: pointer;
			text-decoration: none;
		}

		&:hover:not(.active) {
			color: $label-color-dark-gray;
		}
	}

	& .active {
		background: $bg-container;
		color: var(--label-color-blue);
		position: relative;

		&::after {
			// To hid bottom border
			content: "";
			width: 100%;
			height: 5px;
			position: absolute;
			bottom: -1px;
			left: 0;
			background-color: $bg-container;
		}
	}

	@media only screen and (max-width: 768px) {
		flex-wrap: nowrap;
		overflow: auto;

		.nav-tabs-item-container {
			order: 2;
		}

		.nav-custom {
			//   width: 100%;
			margin-right: 0.5rem;
			//   margin-bottom: 0.5rem;
			border-bottom: 1px solid $border-color;
			font-size: 1rem;
			white-space: nowrap;
		}

		.active::after {
			display: none;
		}
	}
}

.tab-custom-content {
	flex: 1;
	min-height: 0;
	background: $bg-container;
	border: 1px solid #d5e7fc;
	display: flex;
	flex-direction: column;
	overflow: auto;

	&.tab-content {
		background: $bg-container;
		height: max-content;
		margin: 0;
	}

	@media only screen and (max-width: 768px) {
		.tab-content {
			flex: 0 1 auto;
			min-height: auto;
		}
	}
}

.tab-custom-pane {
	flex-grow: 1;
	min-height: 0;
}

.mh-0 {
	min-height: 0;
}

.h-web-100 {
	height: 100%;

	@media screen and (max-width: 450px) {
		height: auto;
	}
}

// margin 10px

.mb-10 {
	margin-bottom: 10px;
}

.mr-10 {
	margin-right: 10px;
}

.mt-10 {
	margin-top: 10px;
}

.ml-10 {
	margin-left: 10px;
}

.mr-auto {
	margin-left: auto;
}

.ml-auto {
	margin-left: auto;
}

.filter-label {
	color: $label-color-gray;
	font-weight: 700;
}

.exam-type {
	white-space: nowrap;
}

thead th {
	padding: 0.8em 0.5em;
	position: -webkit-sticky;
	/* for Safari */
	position: sticky;
	top: -17px;
}

thead .msa-header {
	padding: 0.8em 0.5em;
	position: -webkit-sticky;
	/* for Safari */
	position: sticky;
	top: 15px;
}

.index-style {
	background-color: $bg-container;
}

.icon-width {
	width: 32px;
	padding: 5px;
}

.nopad {
	padding: 0;
}

.nav-tabs {
	// background: none;
	// border-top: none;
	border-bottom: none;
	margin: 1.25em 0;
}

.table-bordered td,
.table-bordered th {
	border-color: #b7d6ff;
	background-clip: padding-box;
}

.table-workbook {
	background-color: $bg-light;

	& thead {
		& th:nth-child(even) {
			background: $bg-White;
		}
	}

	// ------------------------
	& tbody {
		& th:nth-child(odd) {
			background: $bg-White;
		}
	}

	& tbody {
		& td:nth-child(odd) {
			background: $bg-White;
		}
	}
}

.data-block {
	padding: 3px;
	background: #fff;
	border: 1px solid #b7d6ff;
	border-radius: 3px;
	text-align: center;
}

.w-8 {
	width: 8%;
}

.w-10 {
	width: 10%;
}

.w-15 {
	width: 15%;
}

.w-20 {
	width: 20%;
}

.w-25 {
	width: 25%;
}

.w-30 {
	width: 30%;
}

.ml-20 {
	margin-left: 20px;
}

.mt-20 {
	margin-top: 20px;
}

.pt-20 {
	padding-top: 20px;
}

.h100-20 {
	height: calc(100% - 20px);
}

.mt-50 {
	margin-top: -50px;
}

.pl-0 {
	padding-left: 0;
}

.font-medium {
	font-weight: 600;
}

label {
	margin-bottom: 0;
}

.themeButton,
.btn-primary {
	background-color: var(--label-color-blue);
	border-color: var(--label-color-blue);
	color: white;
	transition: all 0.2s;

	&:hover:not(.disabled, :disabled) {
		background-color: transparent;
		border-color: var(--label-color-blue);
		color: var(--label-color-blue);

		.customIcon {
			// Filtering SVG colors
			filter: brightness(0) saturate(100%) invert(52%) sepia(98%) saturate(1925%) hue-rotate(193deg) brightness(98%) contrast(87%);
		}
	}

	&.disabled,
	&:disabled {
		background-color: $label-color-gray;
		border-color: $label-color-gray;
		color: white;
	}
}

.btn-outline-primary {
	background-color: transparent;
	border-color: var(--label-color-blue);
	color: var(--label-color-blue);
	transition: all 0.2s;

	&:hover:not(.disabled, :disabled) {
		background-color: var(--label-color-blue);
		border-color: var(--label-color-blue);

		.customIcon {
			// Filtering SVG colors
			filter: brightness(0) saturate(100%) invert(100%);
		}
	}

	&.disabled,
	&:disabled {
		background-color: transparent;
		border-color: $label-color-gray;
		color: $label-color-gray;
	}
}

.btn-cancel {
	color: #676869;
	font-weight: 600;
	padding-left: 0;

	&:hover:not(.disabled),
	&:hover:not(:disabled) {
		.customIcon {
			// Filtering SVG colors
			filter: brightness(0) saturate(100%) invert(52%) sepia(98%) saturate(1925%) hue-rotate(193deg) brightness(98%) contrast(87%);
		}
	}
}

.btn-primary.white-bg {
	&:hover:not(.disabled, :disabled) {
		background-color: white;
	}
}

.btn-outline-primary.white-bg {
	background-color: white;

	&:hover:not(.disabled, :disabled) {
		background-color: var(--label-color-blue);
	}
}

.btn-cancel {
	color: #676869;
	font-weight: 600;
	padding-left: 0;

	&:hover:not(.disabled),
	&:hover:not(:disabled) {
		.customIcon {
			// Filtering SVG colors
			filter: brightness(0) saturate(100%) invert(52%) sepia(98%) saturate(1925%) hue-rotate(193deg) brightness(98%) contrast(87%);
		}
	}
}

.btn-primary.white-bg {

	&:hover:not(.disabled),
	&:hover:not(:disabled) {
		background-color: white;
	}
}

.btn-outline-primary.white-bg {
	background-color: white;

	&:hover:not(.disabled),
	&:hover:not(:disabled) {
		background-color: var(--label-color-blue);
	}
}

.btn-danger {
	background-color: $bg-second-red;
	border-color: $bg-second-red;
}

.btn-switch {
	margin-top: 0.5em;
	margin-bottom: 2rem;
	list-style: none;
	width: 100%;
	width: max-content;
	background: white;
	color: #4f92ea;
	border-radius: 2.5em;
	border: 1px solid #4f92ea;

	display: flex;

	& li {
		display: flex;
		align-items: center;
		line-height: 2.3rem;
		justify-content: center;
		padding: 0.75rem 1.5rem;
		border-radius: 2.5em;
		text-transform: capitalize;
		text-decoration: none;
		font-weight: 600;
		font-size: 1em;

		&.active {
			background: #4f92ea;
			color: white;
			padding: 0.75rem 2.5rem;
		}
	}
}

.btn-blue-primary {
	background-color: var(--label-color-blue);
	color: #fff;

	&:hover:not(.disabled),
	&:hover:not(:disabled) {
		background-color: transparent;
		border-color: var(--label-color-blue);
		color: var(--label-color-blue);
	}

	&.disabled,
	&:disabled {
		background-color: $label-color-gray;
		border-color: $label-color-gray;
	}
}

.da-teacher-gss-select,
.da-admin-gss-select {
	overflow: visible;

	.text-search {
		width: 100%;
		height: 100%;
		outline: none;
		border: none;
	}

	.admin-gss-dropdown-menu {
		max-height: 30rem;
		min-width: 100%;
		width: max-content;
		overflow-y: auto;
		background: white;
	}

	.gss-values {
		padding-left: 2.5rem;
	}

	.dropdown-menu.teacher-gss-dropdown-menu.show,
	.dropdown-menu.admin-gss-dropdown-menu.show {
		z-index: 1030;
	}

	.grade .fa {
		margin-left: 1rem;
	}

	.dropdown-item:hover {
		background: #f2f7ff;
		color: #aaaaaa;
	}

	// There's no way to get around this at the moment
	.da-teacher-dropdown-toggle {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: default;
		display: flex;
		align-items: center;

		&-content {
			padding: 0.5em 4em 0.5em 1em;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

// b-Admin-new-styling -- BS
.bAns {
	&-main {
		border: 1px solid #C9E0FB;
		background-color: #f2f7ff;
		border-radius: 0.3rem;
		margin: .5rem 1rem .5rem 0rem;
		padding: .65rem;
		display: flex;
		align-items: center;

		& span {
			font-weight: 600;
			color: #676869;
		}
	}

	&-container {
		margin-top: -.6rem;
		border-top: none;
	}

	&-content {
		padding: .55rem 0rem .55rem 1rem !important;
		border: 1px solid #C9E0FB;

		&:last-child {
			border-radius: 0em 0em .3em .3em;
		}
	}

	&-bgActive {
		background-color: $light-dark-green !important;
		border: 1px solid $active;

		& span {
			color: $active;
			font-weight: 500;
		}
	}
}

.select-question-field.da-teacher-gss-select {
	display: flex;
	align-items: center;
	min-width: max-content;
	overflow: visible;
	color: $label-color-dark-gray;

	&.disabled {
		background-color: #fafafa;
		background-image: linear-gradient(45deg, transparent 50%, #f2f2f2 50%),
			linear-gradient(135deg, #f2f2f2 50%, transparent 50%),
			linear-gradient(to right, #bec4cf, #bec4cf);
	}
}

.dropdown-menu.teacher-gss-dropdown-menu {
	max-height: 30rem;
	min-width: 100%;
	width: max-content;
	overflow-y: auto;
	background: white;
	padding: 0 !important;

	.dropdown-item {
		padding: 0.5rem 1rem;
		color: $label-color-dark-gray-2;
		cursor: default;
	}

	.dropdown-item:hover {
		background: #f2f7ff;
	}

	.dropdown-item.selected {
		background-color: $label-color-blue;
		color: white;
	}
}

// ===========================================================

.upload-book-title {
	font-size: 1em;
	font-weight: 500;
	background: (var(--label-color-blue));
	padding: 0.7rem 1.4rem;
	border-radius: 1rem;
	position: absolute;
	top: -15px;
	color: #fff;
}

.light {
	background: $light;
}

.accordion-addEditUser {
	background: #e3f4fe;
	margin: 0.5em 0.2em;
}

.light-gray {
	color: #bec4cf;
}

#switch {
	height: 0;
	width: 0;
	visibility: hidden;
}

.pcr-app.visible {
	left: 64em !important;
}

.toggle-switch {
	cursor: pointer;
	text-indent: -9999px;
	width: 25px;
	height: 18px;
	background: #bec4cf;
	display: block;
	border-radius: 100px;
	position: relative;
	margin-top: -12px;
	margin-bottom: 0;
	// &.revert {
	// 	transform: scale(-1.2);
	// }
}

.toggle-switch:after {
	content: "";
	position: absolute;
	top: 3px;
	left: 2px;
	width: 12px;
	height: 12px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked+.toggle-switch {
	background: #1abf73;
}

input:checked+.toggle-switch:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

input:disabled {
	cursor: not-allowed;
}

.toggle-switch:active:after {
	width: 20px;
}

// ===========================================================

::-webkit-file-upload-button {
	background: #e6f0ff;
	color: #676869;
	padding: 0.8em;
	border: 1px solid #e6f0ff;
}

.custom-input-upload {
	position: relative;
	margin-top: 1.5rem;

	.custom-upload-label {
		position: absolute;
		min-height: 1.5rem;
		// height: 100%;
		height: calc(3.5rem - 2px);
		display: flex;
		align-items: center;
		padding: 0.8em;
		background: #e6f0ff;
		border: 1px solid #e6f0ff;
		border-radius: 10px 0 0 10px;
		cursor: pointer;
		margin: 0;
		margin: 1px 0 0 1px;
	}

	.form-control-file {
		display: flex;
		align-items: center;

		width: 100%;
		line-height: 3rem;
		height: 3.5rem !important;

		border: 1px solid #e6f0ff;
		border-radius: 10px;

		padding-left: 9rem !important;
		padding-right: 2rem !important;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: $label-color-dark-gray;
		font-style: italic;
		font-weight: 500;
	}

	input[type="file"] {
		display: none;
	}

	::-webkit-file-upload-button {
		background: transparent;
		color: transparent;
		border: none;
		padding: 0;
	}
}

.no-pad {
	padding: 0 !important;
}

.no-mar {
	margin: 0;
}

.no-border {
	border: none;
}

.text-primary {
	color: #204f8d !important;
	margin: 0;
}

.text-label-gray {
	color: $label-color-gray;
}

.text-label-dark-gray {
	color: $label-color-dark-gray;
}

.workbook-dropdown .dropdown-item {
	background: $bg-light;
	font-size: 1.875em;
	border-bottom: 0.063em solid #b7d6ff;
	padding: 0.625em 0.313em;
	color: $bg-blue;
	width: 8em;
	text-align: center;
}

.overlay {
	background: #00000070;
	border-top-left-radius: 18px;
}

.subject-list {
	max-height: 36em;
	overflow-x: hidden;
	overflow: auto;
	left: -3em !important;

	& div {
		display: flex;
		padding: 0.5em;

		& a {
			align-self: center;
			font-size: 1em;
			font-weight: 400;
			color: inherit;
		}

		& a:hover {
			color: inherit;
			background: transparent;
			cursor: inherit;
		}

		&:hover {
			background: rgba($fadedThemeColor, 0.1);
			cursor: pointer;
		}
	}
}

// .dynamicHtmlClass {
//   & img {
//     width: 100%;
//   }
// }

.card-footer {
	background-color: #e6f0ff !important;
}

// .card-footer-summary {
// 	background-color: #4F92EA 100%;
// 	padding: 0.25rem 1.5rem;
// }

.submngt-sect {
	.subject-dropdown {
		max-height: 35em;
		overflow: auto;
		min-width: max-content;
		max-width: 100%;
	}

	.subject-dropdown .dropdown-item {
		background: #fff;
		font-size: 1.875em;
		padding: 0.625em 0.313em;
		color: $bg-blue;

		& .show {
			height: 10em;
			text-align: left;
		}
	}
}

.btn-subject-main {
	padding: 0.9rem 1.5rem;
	border-radius: 0.3rem;
}

.workbook-year-label {
	background: $bg-blue;
	font-size: 1.25em;
	padding: 0.438em 2.563em;
	color: white;
	font-weight: 500;
	border-bottom-left-radius: 6.25em;
	border-bottom-right-radius: 6.25em;

	&.dropdown-toggle::after {
		margin-left: 1rem !important;
	}
}

.workbook-year-label:hover {
	color: white;
}

.workbook-year-dropdown .dropdown-item {
	background: $bg-light;
	font-size: 1.25em;
	border-bottom: 0.063em solid #b7d6ff;
	padding: 0.625em 0.313em;
	color: $bg-blue;
	width: 13.125em;
	text-align: center;
}

.rounded-xl {
	border-radius: 0.6rem;
}

.nav-preview {
	width: max-content;
}

.tab-sumary {
	font-weight: 600;
	color: #676869;
	border: 1px solid #e3f4fe;
	border-top-left-radius: 0.6em;
	border-top-right-radius: 0.6em;
	margin-right: 0.5em;

	& .active {
		background: #d5e7fc !important;
		color: var(--label-color-blue);
	}
}

.tab-sumary:hover #imgSumary,
.tab-sumary.active #imgSumary {
	background-color: var(--label-color-blue);
	border-top-left-radius: 0.6em;
	border-bottom-left-radius: 0.6em;
}

.borderRadius {
	border-radius: 0.6em !important;
	border: 1px solid #e3f4fe;
	cursor: pointer;

	&:hover {
		background-color: #d5e7fc;
		color: var(--label-color-blue) 100%;
		border-radius: 0.6em !important;
	}

	&.buttonAdd {
		padding: 0.8em;
		background-color: $bg-White;
		color: inherit;

		&:hover {
			background-color: var(--label-color-blue);
			color: white;
		}
	}
}

// catatan===
.videoThumbContainer {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	/* max-width: max-content; */
	max-width: 100%;
	max-height: 100%;
	border-radius: 0.75rem;
	// box-sizing: content-box;
	cursor: pointer;

	& img {
		height: 100%;
		width: 100%;
		max-height: 15rem;
		object-fit: cover;
		border-radius: inherit;
	}

	&:hover .playClass {
		transform: scale(1.2);
		transition: 0.35s;
	}
}

.playClass {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(white, 0.8);
	border-radius: 50%;
	height: 4rem;
	width: 4rem;
	cursor: pointer;
	transition: 0.35s;
}

.badge-preload {
	color: #676869;
	background-color: #cacbce;
	font-size: 0.5em;
	padding: 4px 10px;
}

.badge-create {
	color: #36bfd7;
	background-color: #d2fbfc;
	font-size: 0.5em;
	padding: 4px 10px;
}

.badge-queue {
	color: #feaf43;
	background-color: #fff0de;
	font-size: 0.5em;
	padding: 4px 10px;
}

.badge-process {
	color: var(--blue-primary);
	background-color: #e3f4ff;
	font-size: 0.5em;
	padding: 4px 10px;
}

.badge-done {
	color: #1abf73;
	background-color: #e6fff4;
	font-size: 0.5em;
	padding: 4px 10px;
}

.badge-error {
	color: #f05974;
	background-color: var(--background-light-red);
	font-size: 0.5em;
	padding: 4px 10px;
}

.blur {
	opacity: 0.3;
}

.mark,
mark {
	border: 1px solid #fffd00;
	border-radius: 3px;
	background: #fff7b2;
	color: inherit;
}

.back-button {
	font-size: 1.385rem;
	color: $label-color-gray;
	transition: 200ms;

	background: transparent;
	border: none;
	outline: none;

	&:hover {
		color: var(--label-color-blue);
	}
}

.lh-0 {
	line-height: 0;
}

.anchor-like {
	color: var(--label-color-blue);
	text-decoration: none;
	background-color: transparent;
	cursor: pointer;

	&.underline {
		text-decoration: underline;
	}
}

.form-div {
	.input-div {
		display: flex;
		flex-direction: column;
		margin-top: 20px;

		&.noTopMar {
			margin-top: 0px;
		}

		.mandatory {
			color: red;
		}

		label {
			color: #232230;
			font-size: 1.1rem;
			font-weight: 600;
		}

		input[type="text"],
		textarea {
			border: 1px solid #C9E0FB;
			border-radius: 3px;
			outline: none;
			padding: 10px;
		}

		input[type="radio"] {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}

		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type=number] {
			-moz-appearance: textfield;
			appearance: textfield;
			margin: 0;
			outline: none;
			border: 0;
			width: min-content;
			border-radius: 3px;
			padding: 10px;
		}

		.loan-interval-input {
			border: 1px solid #C9E0FB;
			border-radius: 3px;
			display: flex;
			justify-content: space-between;

			p {
				margin: 0px;
				padding: 10px;
			}

			.arrow-div {
				background-color: #4F92EA;
				height: 50%;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				.up {
					width: 0;
					height: 0;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;

					border-bottom: 7px solid white;
				}

				.down {
					width: 0;
					height: 0;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;

					border-top: 7px solid white;
				}
			}
		}

		.dropdown-toggle::after {
			display: inline-block;
			border-top: 0em solid #F2F2F2;
			border-right: 0em solid transparent;
			border-bottom: 0;
			border-left: 0em solid transparent;
		}

		.dropdown-toggle {
			width: 100%;
			min-width: 350px;
			border-color: #b7d6ff;
			padding: 0.5em 4em 0.5em 1em;
			color: gray;
			height: 3.2em;
			background-color: white;
			border-radius: 0.438em;
			display: flex;
			align-items: center;
			outline: 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-flex-item-align: center !important;
			align-self: center !important;

			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			// Create Background and White Arrow
			background-image: linear-gradient(45deg, transparent 50%, #f2f2f2 50%),
				linear-gradient(135deg, #f2f2f2 50%, transparent 50%),
				linear-gradient(to right, var(--label-color-blue), var(--label-color-blue));

			// Positioning Background and White Arrow
			background-position: calc(100% - 1.4em) calc(1em + 0.071em),
				calc(100% - 1em) calc(1em + 0.071em), 100% 0;
			// Sizing White Arrow
			background-size: 0.429em 0.429em, 0.429em 0.429em, 3em 4em;
			background-repeat: no-repeat;

			&.booktype-dropdown {
				min-width: auto;
				width: 13rem;
			}

			&.library-dropdown {
				min-width: auto;
				width: 20rem;
			}

			&.sort-dropdown {
				min-width: auto;
				width: 9rem;
			}
		}

		.dropdown-toggle:focus {
			background-image: linear-gradient(45deg, #f2f2f2 50%, transparent 50%),
				linear-gradient(135deg, transparent 50%, #f2f2f2 50%),
				linear-gradient(to right, var(--label-color-blue), var(--label-color-blue));
			background-position: calc(100% - 1em) 1em, calc(100% - 1.4em) 1em, 100% 0;
			background-size: 0.429em 0.429em, 0.429em 0.429em, 3em 4em;
			background-repeat: no-repeat;
			border-color: grey;
			outline: 0;
		}

		.dropdown-toggle:disabled {
			font-weight: 500;
			background-color: #fafafa;
			background-image: linear-gradient(45deg, transparent 50%, #f2f2f2 50%),
				linear-gradient(135deg, #f2f2f2 50%, transparent 50%),
				linear-gradient(to right, #bec4cf, #bec4cf);
		}

		.dropdown-menu {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: 0px;
			padding: 0px;
		}

		.dropdown-item {
			display: flex;
			padding: 0.7rem 1rem;
			font-size: 1rem;
			font-weight: 500;
			color: #676869;
			width: 100%;
			background-color: #FFFFFF;
		}

		.dropdown-item:hover {
			background: #F2F7FF
		}

		.dropdown-item.selected {
			background-color: $label-color-blue;
			color: white;
		}

		// .dropdown-toggle::before {
		//     position: absolute;
		//     content: "";
		//     margin-left: calc(85%);
		//     margin-top: 20px;
		//     width: 40px;
		//     height: 98%;
		//     background-image: url("../../assets/svg/blue-down-arrow.svg");
		// }
		.radio-input-div {
			margin-top: 5px;
		}

		.radio-margin-left {
			margin-left: 30px;
		}
	}
}


.button-wrapper-filter-modal {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	align-items: center;

	.cancel-btn {
		color: #676869;
		font-weight: 600;
		cursor: pointer;
		font-size: 1.1rem;
		margin-left: 25px;
	}

	.create-btn {
		background-color: #4F92EA;
		color: #FFFFFF;
		padding: 8px 15px;
		border-radius: 5px;
		cursor: pointer;
	}
}

.double-date {
	display: flex;
	align-items: center;
	border: 1px solid #C9E0FB;
	margin: 0px;
	padding: 0px;
	width: 350px;
	height: 42px;
	border-radius: 3px;

	span {
		margin: 0px 15px;
	}

	.form-calendar {
		border: none;
		box-shadow: none;

		// width: 20px;
		input[type="text"] {
			border: none;
			box-shadow: none;
			width: 140px;
		}
	}

	.rdtPicker {
		right: 0
	}

	.icon-div {
		display: flex;
		min-width: 35px;
		min-height: 42px;
		align-items: center;
		justify-content: center;
		background-color: #4F92EA;
	}
}

.vertical-line {
	margin-left: 25px;
	margin-right: 25px;
	margin-top: 20px;
	border-left: 1px solid #BEC4CF;
}

.no-tooltip {
	box-shadow: 4px 4px 4px 4px #ECF0F3;
	position: relative;

	height: max-content;
	width: max-content;

	background-color: white;
	color: black;

	font-weight: 500;
	font-size: 0.85rem;

	border-radius: 2px;
	padding: 30px;
	margin-left: 1rem;

	// Bootstrap modal being a bruh momentum
	z-index: 1100;

	&.no-pad {
		padding: 0px;
		margin-left: 0px;
	}

	&.dropdown-outer-div {
		.dropdown-option {
			margin: 0px;
			padding: 5px 40px 5px 10px;
			cursor: pointer;

			&:hover {
				background: #F2F7FF;
			}
		}
	}
}

// Right Tooltip
.right-tooltip {
	position: relative;
	display: flex;
	flex-direction: column;

	height: max-content;
	width: max-content;
	max-width: 25rem;

	background-color: $label-color-dark-gray;
	color: white;

	font-weight: 500;
	font-size: 0.85rem;

	border-radius: 0.5rem;
	padding: 0.5rem;
	margin-left: 1rem;

	// Bootstrap modal being a bruh momentum
	z-index: 1100;

	&.none {
		background-color: white;
		color: black;
	}

	&::before {
		content: "";
		position: absolute;

		width: 0;
		height: 0;

		top: 50%;
		left: -0.8rem;
		transform: translateY(-50%);

		border-top: 0.5rem solid transparent;
		border-bottom: 0.5rem solid transparent;
		border-right: 0.8rem solid $label-color-dark-gray;
	}
}

.bottom-right-tooltip {
	position: relative;
	display: flex;
	flex-direction: column;

	height: max-content;
	width: max-content;
	max-width: 25rem;

	background-color: $label-color-dark-gray;
	color: white;

	font-weight: 500;
	font-size: 0.85rem;

	border-radius: 0.5rem;
	border-bottom-right-radius: 0;
	padding: 0.5rem;
	margin-bottom: 1rem;

	// Bootstrap modal being a bruh momentum
	z-index: 1100;

	&::before {
		content: "";
		position: absolute;

		width: 0;
		height: 0;

		right: 0;
		bottom: -0.8rem;

		border-top: 0.8rem solid $label-color-dark-gray;
		border-right: 0.5rem solid transparent;
		border-left: 0.5rem solid transparent;
	}
}

.top-right-tooltip {
	position: relative;
	display: flex;
	flex-direction: column;

	height: max-content;
	width: max-content;
	max-width: 25rem;

	background-color: $label-color-dark-gray;
	color: white;

	font-weight: 500;
	font-size: 0.85rem;

	border-radius: 0.5rem;
	border-top-right-radius: 0;
	padding: 0.5rem;
	margin-top: 1rem;

	// Bootstrap modal being a bruh momentum
	z-index: 1100;

	&::before {
		content: "";
		position: absolute;

		width: 0;
		height: 0;

		right: 0;
		top: -0.8rem;

		border-bottom: 0.8rem solid $label-color-dark-gray;
		border-right: 0.5rem solid transparent;
		border-left: 0.5rem solid transparent;
	}
}

.multiple-modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 2050;
	pointer-events: none;
}

.bottom-notice {
	pointer-events: auto;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1200;

	color: white;
	background: $label-color-green;
	border-radius: 0.769rem 0.769rem 0 0;

	display: flex;
	justify-content: center;
	align-items: center;

	padding: 1.538rem 1.923rem;
	font-size: 1.154rem;
	font-weight: 500;

	&.errorMessage {
		background: $label-color-red;
	}
}

.school-top-logo {
	width: auto;
	height: 2.308rem;
	margin-left: 1rem;
}

.overflow-visible {
	overflow: visible;
}

.text-label-gray {
	color: $label-color-gray !important;
}

.text-label-blue {
	color: var(--label-color-blue) !important;
}

.text-label-red {
	color: $label-color-red !important;
}

.text-label-dark-gray {
	color: $label-color-dark-gray !important;
}

.text-label-dark-gray-2 {
	color: $label-color-dark-gray-2 !important;
}

.text-label-b-black {
	color: #000 !important;
}

.font-weight-semibold {
	font-weight: 600;
}

.font-weight-medium {
	font-weight: 500;
}

.choose-or {
	display: flex;
	justify-content: space-between;

	hr {
		width: 100%;
		border-top: 0.1rem solid #bec4cf;
	}

	align-items: center;

	span {
		padding: 0px 1rem;
		color: #000000;
	}
}

.mh-0 {
	min-height: 0;
}

.scrollable {
	flex: 1;
	min-height: 0;
	overflow: auto;

	&.hide-scroll {

		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
	}
}

.scrollable-fullscreen {
	min-height: max-content;
	max-height: 100%;
	overflow: auto;

	&.hide-scroll {

		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
	}
}

// WYSIWYG STYLING FIX
.rdw-image-modal-upload-option-label {
	word-break: break-all;
	align-items: flex-start;
	max-height: 10rem;
	overflow: hidden;
}

.toolbarClassName {
	display: flex;
	flex-wrap: wrap;

	& * {
		color: inherit;
		text-decoration: inherit;

		&:hover {
			color: inherit;
			text-decoration: inherit;
		}
	}
}

.editorClassName,
.editorSmallClassName,
.topicEditorClassName,
.wysiwyg-viewer {
	overflow: auto;

	& * {
		font-size: inherit;
		font-family: inherit;
		line-height: initial;
	}

	// MathJax equation
	.mjx-chtml * {
		line-height: unset;
	}

	ul,
	ol {
		padding-left: 1.5rem;
		margin-bottom: 0;
		// list-style-type: initial !important;
	}

	ul {
		list-style: initial !important;
	}

	h1 {
		font-size: 2.5rem;
	}

	h2 {
		font-size: 2rem;
	}

	h3 {
		font-size: 1.75rem;
	}

	h4 {
		font-size: 1.5rem;
	}

	h5 {
		font-size: 1.25rem;
	}

	h6 {
		font-size: 1rem;
	}

	blockquote {
		margin: 1rem 0;
		border-left: 5px solid #f1f1f1;
		padding-left: 5px;
		overflow: hidden;
	}

	pre {
		font-family: Consolas, serif;
		color: #e83e8c;
		word-break: break-word;
		margin: 1rem 0;
		background: #f1f1f1;
		border-radius: 3px;
		padding: 1px 10px;
	}

	.public-DraftEditorPlaceholder-root {
		padding: 1rem;
	}

	& .public-DraftStyleDefault-block {
		margin: 0;
	}

	& .public-DraftStyleDefault-ltr {
		padding: 0;
	}

	& .public-DraftEditor-content[contenteditable="true"] {
		padding: 1rem;
	}

	/* So that our Image won't scrolled */
	img {
		max-width: 100%;
		height: auto;
		// width: max(10%, 100%);
	}

	table,
	th,
	td {
		border: 1px solid #bbb;
		padding: 0.5rem;
	}
}

.input-custom {
	// width: 2.188rem;
	// height: 2.188rem;
	// color: #fff;
	// border-color: var(--label-color-blue);
	// background-color: var(--label-color-blue);
	// top: 0;

	margin-left: -1.5em;
	margin-right: 0.5em;
	margin-bottom: 0.3em;
}

.select-template-form {
	width: 100%;
	overflow: auto;
	height: 12rem;
	border-radius: 0.5rem;
	border: 1px solid $border-color;
	padding: 2rem;
	margin-bottom: 2rem;
	margin-top: 1rem;
	position: relative;

	.infinite-scroll-component__outerdiv {
		overflow: auto;
		height: 100%;
	}

	& .grid-infinite {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(15.385rem, 1fr));
		grid-auto-rows: 3.385rem;
		justify-items: stretch;
		grid-gap: 2rem;

		&::-webkit-scrollbar {
			display: none;
		}

		@media only screen and (max-width: 1150px) {
			grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
		}
	}
}

.font-placeholder {
	color: $label-color-gray;
}

.flex-all-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// Breadcrumbs
.custom-breadcrumb {
	display: flex;
	align-items: center;
	padding: 0.16rem 0;

	ol,
	ul,
	li {
		list-style: none;
	}

	.fa-arrow-left,
	.fa-arrow-right {
		font-size: 1.5rem;
		color: #bec4cf;
		font-weight: normal;
		transition: all 0.2s;

		&:hover {
			text-decoration: none;
			color: #4f92ea;
		}
	}
}

.custom-breadcrumb-item {
	& span {
		font-size: 1.385rem;
		color: $label-color-gray;
		font-weight: 500;
		transition: all 0.2s;

		&:hover {
			text-decoration: none;
			color: var(--label-color-blue);
		}
	}

	& .active {
		color: var(--label-color-blue);
		font-weight: 600;
	}

	&+& {
		padding-left: 0.9rem;
	}

	&+&::before {
		font-family: FontAwesome;
		content: "\f054";
		font-weight: normal;
		padding-right: 0.9rem;
		color: $label-color-dark-gray;
	}
}

// responsive===================
.flex-row-web {
	flex-direction: row;

	@media only screen and (max-width: 450px) {
		flex-direction: column;
	}
}

@media only screen and (max-width: 720px) {
	.text-desc-only {
		visibility: hidden;
		width: 0;
		height: 0;
	}

	.upload-book-title {
		position: unset;
	}
}

@media only screen and (min-width: 720px) {
	.icon-tab-responsive {
		visibility: hidden;
		width: 0;
		height: 0;
	}
}

ul {
	list-style: none;
}

.w-md-50 {
	width: 100% !important;

	@media screen and (min-width: 768px) {
		width: 50% !important;
	}
}

//tooltip
.custom-tooltip {
	position: relative;
	// display: flex;

	// Bootstrap modal being a bruh momentum
	z-index: 1100;

	&.show {
		opacity: 1;
	}

	.tooltip-inner {
		height: max-content;
		width: max-content;
		max-width: 25rem;

		background-color: $label-color-dark-gray;
		color: white;

		font-weight: 500;
		font-size: 0.85rem;

		border-radius: 0.5rem;
		padding: 0.5rem;
		text-align: left;
	}

	&.long .tooltip-inner {
		max-width: 50rem;
	}

	&.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
	&.bs-tooltip-bottom .arrow::before {
		border-bottom-color: $label-color-dark-gray;
	}

	&.bs-tooltip-auto[x-placement^="top"] .arrow::before,
	&.bs-tooltip-top .arrow::before {
		border-top-color: $label-color-dark-gray;
	}

	&.bs-tooltip-auto[x-placement^="left"] .arrow::before,
	&.bs-tooltip-left .arrow::before {
		border-left-color: $label-color-dark-gray;
	}

	&.bs-tooltip-auto[x-placement^="right"] .arrow::before,
	&.bs-tooltip-right .arrow::before {
		border-right-color: $label-color-dark-gray;
	}

	span {
		display: block;
	}
}

#react-joyride-portal,
#react-joyride-step-1,
#react-joyride-step-2,
#react-joyride-step-3,
#react-joyride-step-4,
#react-joyride-step-5,
#react-joyride-step-6,
#react-joyride-step-7,
#react-joyride-step-8,
#react-joyride-step-9,
#react-joyride-step-10 {
	width: 100vw;
	overflow: hidden;
}

.border-bot {
	border-bottom: 1px solid #dee2e6;
}

.border-bottom-mobile {
	display: none;
}

.confirm-blueBtn .btn.yesDeleteMeeting {
	background-color: $label-color-blue;
}

.web-only-show {
	display: initial;
}

.mobile-only-show {
	display: none;
}

@media only screen and (max-width: 450px) {
	.pageHeading {
		margin-top: 0;
	}

	.bottom-notice {
		padding: 1rem !important;
		font-size: 1rem !important;
		width: 80% !important;
	}

	.overlay {
		border-top-left-radius: 0 !important;
	}
}

@media only screen and (max-width: 450px) {
	.web-only-show {
		display: none;
	}

	.mobile-only-show {
		display: initial;
	}

	.pageHeading {
		font-size: 1.5rem;
	}

	.fc-center {
		font-size: 1rem !important;
	}

	.border-heading {
		border-bottom: 2px solid #e3edfd;
		margin-bottom: 0;
		padding: 5px 0px;
		flex-direction: column;
	}

	* {
		font-size: 11px;
	}

	.border-bot {
		border-bottom: 0px;
	}

	.border-bottom-mobile {
		display: block;
		border-bottom: 1px solid #dee2e6 !important;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}
}

.snackbar-bottom-notice {
	pointer-events: auto;
	position: fixed;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1200;

	color: white;
	background: $label-color-green;
	border-radius: 0.769rem 0.769rem 0 0;

	display: flex;
	justify-content: center;
	align-items: center;

	padding: 1.538rem 1.923rem;
	font-size: 1.154rem;
	font-weight: 500;

	&.errorMessage {
		background: $label-color-red;
	}

	&.warningMessage {
		background: $label-color-orange;
		color: $label-color-dark-gray-2;
	}
}

.cqm-upload {
	padding: 2rem;
	font-weight: 600;
	font-size: 1.154rem;
	color: $label-color-dark-gray-2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 100%;
	background: transparent;

	border-radius: 0.5rem;
	border: 2px dashed $label-color-gray;
	box-shadow: inset 2px 3px 7px rgba(black, 0.16);
	// box-shadow: 0 1px 9px #B0D1FC;

	// &:hover,
	// &:active {
	// 	box-shadow: inset 2px 3px 7px rgba(black, 0.16);
	// }

	&.has-file {
		box-shadow: 0 1px 9px #b0d1fc;
	}
}

.cqm-icon {
	width: 2.154rem;
	height: 2.154rem;
	margin-right: 1.154rem;
}

input#ObiWan.csv-input {
	display: none;
	// content: none;
	// border: 1px solid #000;
	// border-style: dashed;
	// background: url(../src/assets/image/icon-import-csv.png);
	// background-color: #f3f3f3;
	// background-repeat: no-repeat;
	// background-position: center;
	// background-size: 40%;
	// padding: 30px 50px;
	// cursor: pointer;
}

.testing {
	border: 2px solid orange !important;
}

.green-pill {
	background: #1abf73 !important;
}